import React from 'react'
import Classnames from 'classnames'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import {connect} from 'react-redux'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ConfirmPopUp from '../../Components/ConfirmPopUp'

import {Globals} from '../../Context'
import UploadMedia from '../../Components/UploadMedia'
import SelectOptionsFlat from './SelectOptionsFlat'
import DatePickerFlat from './DatePickerFlat'
import {addToast, handleGetGlobalColumnConfigs} from './../../app/appActions'
import ServerAutoSuggest from './AutoSuggest'
import * as collections from '../../utils/collections'
import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import toNumber from 'lodash/toNumber'
import debounce from 'lodash/debounce'
import sortBy from 'lodash/sortBy'
import remove from 'lodash/remove'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const CustomMobileModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
    },
    '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '100%',
        margin: 0,
    },
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
		  borderColor: '#D9E2EE',
		},
	}
}));


class AddInventoryComponent extends React.Component {
	constructor() {
		super()
		this.state = {
			sendingData: false,
			formData: {},
			formErrors: {},
			success: false,
			editMode: false,
			presetPost: {},
			warrantyCardsList: Globals.warrantyCardsList.other,
			customColumn: [],
			customColumnValues: {},
			images: [],
			currentListOption: [],
			currentMenuIndex: null,
			chosen: "Select",
			focusedItemIndex: -1,
			initFormData: {},
			visibleFields: [],
		}
		this.onChangePrice = this.onChangePrice.bind(this)
		this.handleMenuClose = this.handleMenuClose.bind(this)
	}

	UNSAFE_componentWillMount() {
		this.setState(
			{
				_presetPost: this.props.post ? JSON.stringify(this.props.post) : null, //Create unimmutable state
			},
			() => {
				if (this.state._presetPost) {
					this.setState({
						presetPost: JSON.parse(this.state._presetPost),
						editMode: true,
						formData: JSON.parse(this.state._presetPost),
					})
				}
			}
		)

		this.setState({
			visibleFields: this.props.globalColumnConfigs.filter(item => item.visible).map(item => item.key)
		})
	}
	async componentDidMount() {
		try {
			const { view } = this.props
			if(!!view && view === 'marketplace') {
				const form = {
					status: "on_hand",
					visible_in_marketplace: "true"
				}
				this.setState({formData: form})

			} else {
				this.setState({formData: {visible_in_marketplace: "false"}, initFormData: {visible_in_marketplace: "false"}})
			}
			this.getCustomColumns()
			this.props.handleGetGlobalColumnConfigs(this.props.currentSpace)
		} catch (e) {
			console.log(e)
		}
	}

	//get custom columns from end point

	handleCheckDataImages = (data) => {
		if(data.length > 0) {
			return true
		}
		return false
	}

	handleCheckDataCustomColumn = (data) => {
		return Object.keys(data).some((column) => {
		  const dataCustomColumn = data[column];
		  let isDifferent = false;
		  if (typeof dataCustomColumn === 'boolean') {
			isDifferent = true;
		  } else if (!!dataCustomColumn) {
			isDifferent = true;
		  } else {
			isDifferent = false;
		  }

		  return isDifferent;
		});
	};

	handleCheckDataColumn = (data, key) => {
		if(!!data) {
			if(key === 'visible_in_marketplace') return false
			return true
		}
		return false
	};

	handleCheckFormHasData = () => {
		const { initFormData , formData } = this.state;
		let isDifferent = !isEqual(formData, initFormData);
		Object.keys(formData).forEach((key) => {
			if(key === 'images') {
				isDifferent = this.handleCheckDataImages(formData[key])
			} else if(key === 'custom_column_values') {
				isDifferent = this.handleCheckDataCustomColumn(formData[key])
			} else {
				isDifferent = this.handleCheckDataColumn(formData[key], key)
			}
		})
		return isDifferent;
	}

	async componentDidUpdate() {
		const  isDifferent = this.handleCheckFormHasData()
		if (isFunction(this.props.handleCheckAddInventoryForm)) return this.props.handleCheckAddInventoryForm(isDifferent);
	}

	goToNextOption = (event, options) => {
		this.setState(prevState => ({
			focusedItemIndex: Math.min(prevState.focusedItemIndex + 1, options.length - 1)
		}));
		event.stopPropagation();
	}

	goToPreviousOption = (event) => {
		this.setState(prevState => ({
			focusedItemIndex: Math.max(prevState.focusedItemIndex - 1, 0)
		  }));
		event.stopPropagation();
	}

	handleKeyDownDropDownCustom = (event) => {
		switch (event.key) {
			case Globals.keyboardButton.arrowDown:
				event.preventDefault();
				this.goToNextOption(event, this.state.currentListOption)
				break;
				case Globals.keyboardButton.arrowUp:
				event.preventDefault();
				this.goToPreviousOption(event)
				break;
			case Globals.keyboardButton.enter:
				event.preventDefault();
				if(this.state.focusedItemIndex === -1) return
				this.setState({focusedItemIndex: -1})
				break;
			default:
		}
	};


	getCustomColumns = () => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/v1/spaces/${Globals.SELECTEDSPACEID}/custom_columns?kind=inventory`)
			.then(response => {
				if (response.status && response.status === 200) {
					this.setState({customColumn: response.data.custom_columns})
				}
			})
			.catch(error => {
				console.log(error)
			})
	}
	setFormValue = fromData => {
		//	this.setState({formData: fromData})

		this.setState(
			{
				formData: {
					...this.state.formData,
					...fromData,
				},
			},
			() => {
				if (fromData.brand) {
					this.setState({
						warrantyCardsList: Globals.warrantyCardsList[fromData.brand.toLowerCase()] || Globals.warrantyCardsList.other,
					})
				}
			}
		)
	}

	handleChange = e => {
		if (e.target.getAttribute('maxLength')) {
			const maxLength = parseInt(e.target.getAttribute('maxLength'))
			const content = e.target.value.slice(0, maxLength)
			e.target.value = content
		}

		this.setState(
			{
				formData: {
					...this.state.formData,
					[e.target.name]: e.target.value,
				},
			},
			() => {
				//Update the box checkbox.
				if (e.target.name === 'box' && this.state.editMode) {
					const presetPost = this.state.presetPost
					presetPost.box = e.target.value === 'true'
					this.setState({
						presetPost: presetPost,
					})
				}

				//Update the box checkbox.

				if (e.target.name === 'warranty_no_date') {
					this.setState({
						formData: {
							...this.state.formData,
							[e.target.name]: e.target.checked,
						},
					})
					const presetPost = this.state.presetPost
					presetPost.warranty_no_date = e.target.checked
					if (e.target.checked) {
						presetPost.warranty = null
					}
					this.setState({
						presetPost: presetPost,
					})
				}

				//Update visibility
				if (e.target.name === 'visible_in_marketplace' && this.state.editMode) {
					const presetPost = this.state.presetPost
					presetPost.visible_in_marketplace = e.target.value
					this.setState({
						presetPost: presetPost,
					})
				}
			}
		)
	}

	onChangePrice = (e, inputName) => {
		let price = e.target.value.replace(/\D/g, "")

		if (!isNil(price) && !isEmpty(price)) {
			price = Number(price)
		}

		this.setState({
			formData: {
				...this.state.formData,
				[inputName]: price,
			},
		})
	}

	handleChangeSelect = e => {
		let newFormState = {
			...this.state.formData,
			[e.target.getAttribute('name')]: e.target.getAttribute('value').trim(),
		}

		if (e.target.getAttribute('name') === 'status' && e.target.getAttribute('value') === 'sold_to' && !newFormState?.sold_date) {
			newFormState['sold_date'] = moment().local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
		}

		this.setState({
			formData: newFormState,
		})
	}

	handlechangePicker = (date, name) => {
		this.setState({
			formData: {...this.state.formData, [name]: date},
		})
	}

	handleChangePickerCustomColumn = (date, name) => {
		const customColumnsValues = { ...this.state.formData.custom_column_values };

		customColumnsValues[name] = moment(date).format('MM/DD/YYYY') || null

		this.setState({
			formData: {
				...this.state.formData,
				custom_column_values: customColumnsValues
			},
		})
	}

	handleChangeCustomColumn = (e) => {
		const { name, type, value } = e.target;
		const customColumnsValues = { ...this.state.formData.custom_column_values };

		if (type === 'radio') {
			customColumnsValues[name] = value === 'true'
		} else if (type==='text') {
			customColumnsValues[name] = value
		} else {
			customColumnsValues[name] = value.trim();
		}

		this.setState(prevState => ({
			formData: {
				...prevState.formData,
				custom_column_values: customColumnsValues
			}
		}));
	};

	handleTrimText = (e) => {

		const { name, type, value } = e.target;
		const customColumnsValues = { ...this.state.formData.custom_column_values };

		if (type === 'text') {
			customColumnsValues[name] = value.trim();
		}

		this.setState(prevState => ({
			formData: {
				...prevState.formData,
				custom_column_values: customColumnsValues
			}
		}));
	}

	uploadImages = pictures => {
		let _pictures = []
		const formData = this.state.formData

		pictures.forEach((p, index) => {
			if (p.done) {
				let newPic = {id: p.post_id.id, order: index + 1}
				_pictures = [..._pictures, newPic]
			}
		})
		formData.images = _pictures
		this.setState({formData: formData, images: pictures})
		//upload images
		/*
		const formData = new FormData()
		formData.append('attachment[file]', pictures)
		formData.append('attachment[order]', 1)
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/attachments`, formData)
			.then(response => {
				const imgData = {id: response.data.id, order: response.data.order}
				if (this.state.formData.images) {
					this.setState({
						formData: {
							...this.state.formData,
							images: [...this.state.formData.images, imgData],
						},
					})
				} else {
					this.setState({
						formData: {
							...this.state.formData,
							images: [imgData],
						},
					})
				}
			})
			.catch(err => {
				console.error(err)
			})
			*/
		//
	}

	isNotANumber = (value) => {
		return isNaN(toNumber(value));
	}

	handleCapture = ({ target }) => {
        const files = target.files;
        const readFiles = Array.from(files).map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = event => {
                    resolve(event.target.result);
                };
                reader.onerror = err => {
                    reject(err);
                };
                reader.readAsDataURL(file);
            });
        });

        Promise.all(readFiles).then(images => this.setState({images: images}));
    };

	handleSubmit = _event => {
		_event.preventDefault()

		const state = this.state
		if (state.sendingData) return //Block user from sending data before the previous request is processed.

		//Make sure all the data is being correct.
		let errors = []
		if (isEmpty(state.formData.sku)) errors = {...errors, sku: 'Dealer SKU is required'}
		if (isEmpty(state.formData.reference_number))
			errors = {
				...errors,
				reference_number: 'Reference Number is required',
			}
		if (isEmpty(state.formData.brand)) errors = {...errors, brand: 'Brand is required'}
		// if (isEmpty(state.formData.series)) errors = {...errors, series: 'Series is required'}

		if (!!state.formData.sold_price && this.isNotANumber(state.formData.sold_price)) {
			errors = {...errors, sold_price: 'Sold Price must be a number'}
		}

		if (!!state.formData.msrp_price && this.isNotANumber(state.formData.msrp_price)) {
			errors = {...errors, msrp_price: 'MSRP Price must be a number'}
		}

		if (!!state.formData.wholesale_price && this.isNotANumber(state.formData.wholesale_price)) {
			errors = {...errors, wholesale_price: 'Wholesale Price must be a number'}
		}

		if (!!state.formData.cost && this.isNotANumber(state.formData.cost)) {
			errors = {...errors, cost: 'Cost must be a number'}
		}

		// if (isEmpty(state.formData.SerialNumber)) errors = { ...errors, SerialNumber: 'Serial Number is required' };
		// if (isEmpty(state.formData.Condition)) errors = { ...errors, Condition: 'Condition is required' };
		// if (isEmpty(state.formData.Dial)) errors = { ...errors, Dial: 'Dial is required' };
		if (isEmpty(state.formData.status)) errors = {...errors, status: 'Status is required'}
		if (state.formData.status?.toLowerCase() === 'sold_to') {
			if (isEmpty(state.formData.sold_price)) {
				errors = {...errors, sold_price: 'Sold Price is required'}
			}

			if (isNil(state.formData.sold_date)) {
				errors = {...errors, sold_date: 'Sold Date is required'}
			}
		}

		if (!!state.formData.status && state.formData.status?.toLowerCase() !== 'on_hand' && state.formData.status?.toLowerCase() !== 'other') {
			if (isEmpty(state.formData.status_details)) {
				errors = {...errors, status_details: 'Name is required'}
			}
		}
		// if (isEmpty(state.formData.Bezel)) errors = { ...errors, Bezel: 'Bezel is required' };
		// if (isEmpty(state.formData.Bracelet)) errors = { ...errors, Bracelet: 'Bracelet is required' };
		// if (isEmpty(state.formData.WarrantyPaper)) errors = { ...errors, WarrantyPaper: 'Warranty Paper/Card is required' };
		// if (isEmpty(state.formData.WarrantyDate)) errors = { ...errors, WarrantyDate: 'Warranty Date is required' };
		// if (isEmpty(state.formData.Box)) errors = { ...errors, Box: 'Box is required' };
		// if (isEmpty(state.formData.LinkCount)) errors = { ...errors, LinkCount: 'Link Count is required' };
		// if (isEmpty(state.formData.Images)) errors = { ...errors, Images: 'at least one picture required' };

		this.setState({formErrors: errors})
		if (isFunction(this.props.handlePrimaryTab)) this.props.handlePrimaryTab()
		if (state.formData.Warranty) {
			const formData = this.state.formData
			formData.WarrantyDate = 'No Date'
			this.setState({formData: formData})
		}

		if (!isEmpty(errors)) {
			return Object.values(errors)
				.reverse()
				.map(error => {
					return this.props.addToast({
						title: 'Cannot add item',
						text: error,
						type: 'error',
					})
				})
		}

		const formData = state.formData

		this.setState({sendingData: true})

		if (formData.warranty_no_date) {
			formData.warranty = null
		}

		if (formData.warranty_papers === 'no') {
			formData.warranty = null
			formData.warranty_no_date = false
		}
		if (!isNil(formData.sold_date)) {
			formData.sold_date = new Date(
				moment(formData.sold_date).year(),
				moment(formData.sold_date).month(),
				moment(formData.sold_date).date(),
				0,
				0,
				0
			)
		}

		if (!isNil(formData.date_purchased)) {
			formData.date_purchased = new Date(
				moment(formData.date_purchased).year(),
				moment(formData.date_purchased).month(),
				moment(formData.date_purchased).date(),
				0,
				0,
				0
			)
		}

		// TODO: need check
		if (!isNil(formData.warranty)) {
			formData.warranty = moment(formData.warranty, 'MM/DD/YYYY').format('DD/MM/YYYY')
		}

		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches`, {
				watch: formData,
			})
			.then(response => {
				this.setState({sendingData: false})

				if (response.status && response.status >= 200 && response.status < 300) {
					if (isFunction(this.props.onDone)) this.props.onDone(response.data, false, true)
					return this.props.addToast({
						title: 'Add Inventory',
						text: 'Your item has been successfully uploaded',
						type: 'success',
					})
				}

				errors = {...errors, error: response.data.errors}
				return this.setState({formErrors: errors})
			})
			.catch(error => {
				this.setState({sendingData: false})
				errors = {
					...errors,
					error: error.response?.data?.message || 'Something went wrong please try again later.',
				}
				this.props.addToast({
					text: error.response?.data?.message || 'Something went wrong please try again later.',
					type: 'error',
				})
				return this.setState({formErrors: errors})
			})
	}

	handleDeleteProduct = $event => {
		$event.preventDefault()

		const state = this.state
		let errors = []

		if (state.sendingData) return //Block user from sending data before the previous request is processed.

		this.setState({sendingData: true})

		return Globals.Axios()
			.post(`${Globals.wsurl}/inventory/delete/inventory`, {
				data: {postId: this.state.formData.id},
			})
			.then(response => {
				this.setState({sendingData: false})

				if (response.data.status) {
					if (isFunction(this.props.onDone)) this.props.onDone(response.data, true)
					return this.props.addToast({
						title: 'Delete Inventory',
						text: 'Your item has been successfully deleted',
						type: 'success',
					})
				}

				errors = {...errors, error: response.data.errors}
				return this.setState({formErrors: errors})
			})
			.catch(error => {
				this.props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
				this.setState({sendingData: false})
				errors = {
					...errors,
					error: 'Something went wrong please try again later.',
				}
				return this.setState({formErrors: errors})
			})
	}

	handleWarrantyCard = debounce(e => {
		const brand = e.target.value.trim().toLowerCase()
		this.setState({warrantyCardsList: Globals.warrantyCardsList[brand] ? Globals.warrantyCardsList[brand] : Globals.warrantyCardsList.other})
	}, 500)

	inventoryStatusesCollection = () => {
		let inventoryStatusesCollection = [...collections.inventoryStatusesCollection];
		if(this.props?.profile.role === "editor"){
			remove(inventoryStatusesCollection, (status) => status.value === "sold_to");
		}
		return inventoryStatusesCollection;
	}

	handleChangeAutoComplete = (event, value) => {
		if(this.state.formData.status === 'memo_to') {
			this.setState({
				formData: {
					...this.state.formData,
					status_details: value.company_name,
				},
			})
		} else {
			this.setState({
				formData: {
					...this.state.formData,
					status_details: value.company_name,
				},
			})
		}
	}

	handleSearchChange(event, customColumn) {
		this.setState({
			search: event.target.value,
			currentListOption: customColumn.list_options.filter((option) => option.value.toLowerCase().includes(event.target.value.toLowerCase())),
		})
	}

	handleMenuOpen(event, index) {
		event.preventDefault()
		this.setState({
			menuAnchorEl: event.currentTarget,
			currentMenuIndex: index,
			currentListOption: this.state.customColumn.find(column => column.key === index).list_options,
		})
    };

	handleMenuClose() {
		this.setState({
			menuAnchorEl: null,
			currentMenuIndex: null,
			search: "",
			focusedItemIndex: -1,
		})
	};

	handleChoseOption(field, customColumn) {
		this.setState({
			currentMenuIndex: null,
			menuAnchorEl: null,

		})
		this.handleChangeListOption(field.value, customColumn)
	}

	handleChangeListOption = (field, customColumn) => {
		const customColumnsValues = { ...this.state.formData.custom_column_values };
		customColumnsValues[customColumn.key] = field

		this.setState(prevState => ({
			formData: {
				...prevState.formData,
				custom_column_values: customColumnsValues
			}
		}))
	}

	renderInputWithConfig = (config, displayError) => {
		const { state, props } = this

		let inputConfig = {onChange: this.handleChange, onSelect: this.handleChange, type: 'text', autoComplete: 'off'}

		if (config.key === 'sku') {
			inputConfig = Object.assign(inputConfig, { label: 'Dealer SKU*', name: 'sku', placeholder: 'SKU' })
		} else if (config.key === 'reference_number') {
			return(
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Reference Number*</div>
					<div className="flex relative">
						<ServerAutoSuggest setFormValue={this.setFormValue} formData={state.formData} />
					</div>
					{displayError('reference_number')}
				</div>
			)
		} else if (config.key === 'brand') {
			inputConfig = Object.assign(inputConfig, {
				label: 'Brand*', name: 'brand', placeholder: 'Brand',
				onSelect: e => {
					this.handleChange(e)
					this.handleWarrantyCard(e)
				},
				onChange: e => {
					this.handleChange(e)
					this.handleWarrantyCard(e)
				}
			})
		} else if (config.key === 'series') {
			inputConfig = Object.assign(inputConfig, { label: 'Series', name: 'series', placeholder: 'Series' })
		} else if (config.key === 'serial_number') {
			inputConfig = Object.assign(inputConfig, { label: 'Serial Number', name: 'serial_number', placeholder: 'Serial Number' })
		} else if (config.key === 'dial') {
			inputConfig = Object.assign(inputConfig, { label: 'Dial', name: 'dial', placeholder: 'Dial' })
		} else if (config.key === 'bezel') {
			inputConfig = Object.assign(inputConfig, { label: 'Bezel', name: 'bezel', placeholder: 'Bezel' })
		} else if (config.key === 'bracelet') {
			inputConfig = Object.assign(inputConfig, { label: 'Bracelet', name: 'bracelet', placeholder: 'Bracelet' })
		} else if (config.key === 'condition') {
			return (
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Condition</div>
					<div>
						<SelectOptionsFlat
							name={'condition'}
							handleChangeSelect={this.handleChangeSelect}
							selectedValue=""
							options={collections.inventoryConditionsCollection}
						/>
					</div>
					{displayError('condition')}
				</div>
			)
		} else if (config.key === 'warranty_papers') {
			return (
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Warranty Paper/Card</div>
					<div>
						<SelectOptionsFlat
							name="warranty_papers"
							handleChangeSelect={this.handleChangeSelect}
							selectedValue=""
							options={state.warrantyCardsList}
						/>
					</div>
					{displayError('warranty_papers')}
				</div>
			)
		} else if (config.key === 'warranty') {
			return(
				this.state.formData?.warranty_papers !== 'no' && (
					<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
						<div>Warranty Date</div>
						<div
							style={{
								width: '100%',
								display: 'inline-flex',
							}}
						>
							{
								<>
									<div
										style={{
											width: '50%',
										}}
									>
										{state.presetPost.warranty_no_date ? (
											<input
												defaultValue={'No Date'}
												value={'No Date'}
												onChange={this.handleChange}
												onSelect={this.handleChange}
												type="text"
												name="WarrantyDate"
												placeholder="No Date"
												className="border-0 m-0 p-0 w-full"
											/>
										) : (
											<DatePickerFlat name="warranty" title="Warranty Date*" handlechangePicker={this.handlechangePicker} defaultValue={null}/>
										)}
									</div>

									<div
										style={{
											width: '50% !important',
											marginLeft: '5px',
										}}
									>
										<input
											onChange={this.handleChange}
											onSelect={this.handleChange}
											style={{
												opacity: '1',
												width: '15px',
											}}
											type="checkbox"
											name="warranty_no_date"
											defaultChecked={state.presetPost.warranty_no_date}
										/>
										<span
											htmlFor="no"
											className="pl-5"
											style={{
												width: '30px !important',
											}}
										>
											No Date
										</span>
									</div>
								</>
							}
						</div>
						{displayError('WarrantyDate')}
					</div>
				)
			)
		} else if (config.key === 'box') {
			return (
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Box</div>
					<div className="flex gap-4 justify-start">
						<React.Fragment>
							<div className="flex gap-1.5 items-center">
								<input onChange={this.handleChange} onSelect={this.handleChange} type="radio" value={true} id="yes" name="box" />
								<label htmlFor="yes">Yes</label>
							</div>
							<div className="flex gap-1.5 items-center">
								<input onChange={this.handleChange} type="radio" value={false} id="no" name="box" />
								<label htmlFor="no">No</label>
							</div>
						</React.Fragment>
					</div>
					{displayError('box')}
				</div>
			)
		} else if (config.key === 'link_count') {
			inputConfig = Object.assign(inputConfig, { label: 'Link Count', name: 'link_count', placeholder: 'Link Count' })
		} else if (config.key === 'addition_details') {
			return (
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Additional Details</div>
					<div>
						<textarea
							placeholder="Maximum of 20000 characters."
							maxLength="20000"
							value={state.formData ? state.formData.addition_details : ''}
							onChange={this.handleChange}
							onSelect={this.handleChange}
							type="text"
							name="addition_details"
							className="border-0 m-0 p-0 w-full"
						></textarea>
						<div>
							<small>{!isNil(state.formData.addition_details) ? state.formData.addition_details.length : 0}</small>
							<small>
								<small>/</small>
							</small>
							<small>
								<small>20000</small>
							</small>
						</div>
					</div>
					{displayError('addition_details')}
				</div>
			)
		} else if (config.key === 'msrp_price') {
			inputConfig = Object.assign(inputConfig, {
				label: 'MSRP Price',
				name: 'msrp_price',
				placeholder: 'MSRP Price',
				onChange: (e) => this.onChangePrice(e, 'msrp_price'),
				reminderText: 'Only whole numbers'
			})
		} else if (config.key === 'wholesale_price') {
			inputConfig = Object.assign(inputConfig, {
				labelWithTooltip: () => {
					return(
					<>
						<div className="whitespace-nowrap">
							Wholesale/Marketplace Price
							<Tooltip title="This is the price that will be shown in the Elefta Marketplace for other dealers to see" placement="bottom" arrow>
								<span className="ml-1 inline-block">
									<InfoOutlinedIcon fontSize="small" />
								</span>
							</Tooltip>
						</div>
					</>
				)
			},
				name: 'wholesale_price',
				placeholder: 'Marketplace Price',
				onChange: (e) => this.onChangePrice(e, 'wholesale_price'),
				reminderText: 'Only whole numbers'
			})
		} else if (config.key === 'cost') {
			return (
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Cost</div>
					<div>
						<input
							value={state.formData.cost}
							onChange={(e) => this.onChangePrice(e, 'cost')}
							type="text"
							name="cost"
							placeholder="Cost"
							className="border-0 m-0 p-0 w-full"
						/>
						<div className="opacity-75">
							<small>Only whole numbers</small>
						</div>
					</div>
				</div>
			)
		} else if (config.key === 'date_purchased') {
			const defaultDate = new Date()
			const day = String(defaultDate.getDate()).padStart(2, '0')
			const month = String(defaultDate.getMonth() + 1).padStart(2, '0')
			const year = defaultDate.getFullYear()
			const formattedDate = `${month}/${day}/${year}`

			return(
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Date Purchased</div>
					<div>
						<DatePickerFlat
							name="date_purchased"
							title="Date Purchased*"
							handlechangePicker={this.handlechangePicker}
							defaultValue={formattedDate}

						/>
					</div>
					{displayError('date_purchased')}
				</div>
			)
		} else if (config.key === 'vendor') {
			inputConfig = Object.assign(inputConfig, { label: 'Vendor/Partner', name: 'vendor', placeholder: 'Vendor' })
		} else if (config.key === 'visible_in_marketplace') {
			return (
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Visible in marketplace</div>
					<div className="flex gap-4 justify-start">
						<React.Fragment>
							<div className="flex gap-1.5 items-center">
								<input onChange={this.handleChange} type="radio" id="yes" value={true} name="visible_in_marketplace" checked={state.formData.visible_in_marketplace === "true"} />
								<label htmlFor="yes">Yes</label>
							</div>
							<div className="flex gap-1.5 items-center">
								<input onChange={this.handleChange} type="radio" id="no" value={false} name="visible_in_marketplace" checked={state.formData.visible_in_marketplace === "false"}
								/>
								<label htmlFor="no">No</label>
							</div>
						</React.Fragment>
					</div>
					{displayError('visible_in_marketplace')}
				</div>
			)
		} else if (config.key === 'online_price') {
			inputConfig = Object.assign(inputConfig, {
				label: 'Website Price',
				name: 'online_price',
				placeholder: 'Website Price',
				reminderText: 'Only whole numbers',
				onChange: (e) => this.onChangePrice(e, 'online_price'),
			})
		} else if (config.is_custom_column === true) {
			const { currentListOption, search, currentMenuIndex, menuAnchorEl } = state
			const column = state.customColumn.find(column => column.key === config.key);
			if ( column === undefined ) return;

			return(
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>{column.label}</div>
					<div>
						{column.field_type === 'boolean' && (
							<div className="flex gap-4 justify-start" key={column.key}>
								<React.Fragment>
									<div className="flex gap-1.5">
										<input
											onChange={this.handleChangeCustomColumn}
											onSelect={this.handleChangeCustomColumn}
											type="radio"
											value={true}
											id={`${column.key}-yes`}
											name={column.key}
										/>
										<label htmlFor={`${column.key}-yes`}>
											Yes
										</label>
									</div>
									<div className="flex gap-1.5">
										<input
											onChange={this.handleChangeCustomColumn}
											onSelect={this.handleChangeCustomColumn}
											type="radio"
											value={false}
											id={`${column.key}-no`}
											name={column.key}
										/>
										<label htmlFor={`${column.key}-no`}>
											No
										</label>
									</div>
								</React.Fragment>
							</div>
						)}

						{column.field_type === 'text' && (
							<input
								key={column.key}
								onChange={this.handleChangeCustomColumn}
								onBlur={this.handleTrimText}
								type="text"
								name={column.key}
								value={state.formData.custom_column_values?.[column.key]}
								placeholder={column.label}
								className="border-0 m-0 p-0 w-full"
							/>
						)}

						{column.field_type === 'float' && (
							<input
								key={column.key}
								onChange={this.handleChangeCustomColumn}
								type="number"
								name={column.key}
								placeholder={column.label}
								className="border-0 m-0 p-0 w-full"
							/>
						)}

						{column.field_type === 'date' && (
							<DatePickerFlat
								name={column.key}
								title={column.label}
								handlechangePicker={this.handleChangePickerCustomColumn}

							/>
						)}

						{displayError(column.key)}

						{column.field_type === "list" && (
							<div onKeyDown={this.handleKeyDownDropDownCustom} className={Classnames("w-3/12 flex items-center justify-start ")}>
								<button onClick={(event) => this.handleMenuOpen(event, column.key)} className="flex items-center whitespace-nowrap">
									{state.formData.custom_column_values?.[column.key] ? state.formData.custom_column_values?.[column.key] : 'Please Select'}
									<ArrowDropDownIcon />
								</button>

								<Menu
									anchorEl={currentMenuIndex === column.key ? menuAnchorEl : null}
									open={Boolean(currentMenuIndex === column.key)}
									onClose={this.handleMenuClose}
									MenuListProps={{
										style: { padding: 0 },
									}}
									sx={{
										'.MuiMenu-paper': {
											borderRadius: '5px',
											border: '1px solid #5D6E81',
										},
									}}
								>
									<div className="flex items-center relative border-b border-[#5D6E81]">
										<input
											className="ps-10 py-2 w-full text-[#5D6E81]" type="text"
											placeholder="Search..."
											value={search}
											onChange={(e) => this.handleSearchChange(e, column)}
										/>
										<div className="absolute left-2 text-[#5D6E81]">
											<SearchIcon className="rotate-90"/>
										</div>
									</div>
									<div className="max-h-[200px] overflow-y-scroll">
										{currentListOption.map((field, fieldIndex) => (
											<MenuItem
												ref={el => {
													if (fieldIndex === this.state.focusedItemIndex && el) el.focus();
												}}
												name={column.key} key={fieldIndex}
												onClick={() => this.handleChoseOption(field, column)}
											>
												<span className="text-[#5D6E81]">{field.value}</span>
											</MenuItem>
										))}
									</div>
								</Menu>
							</div>
						)}
					</div>
				</div>
			)
		}

		if (!inputConfig.name) return

		return (
			<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
				<div>{inputConfig.label || inputConfig?.labelWithTooltip()}</div>
				<div>
					<input
						{...inputConfig}
						defaultValue={state.editMode && state.presetPost ? state.presetPost?.[config.key] : ''}
						value={state.formData ? state.formData?.[config.key] : ''}
						type={inputConfig.type}
						name={inputConfig.name}
						placeholder={inputConfig.placeholder}
						className="border-0 m-0 p-0 w-full"
					/>
					{
						inputConfig.reminderText && (
							<small className="opacity-75">{inputConfig.reminderText}</small>
						)
					}
					{displayError(inputConfig.name)}
				</div>
			</div>
		)
	}

	render() {
		const props = this.props
		const state = this.state
		const { globalColumnConfigs } = props
		const displayError = key => {
			if (!isEmpty(state.formErrors[key])) return <div className="text-[14px] md:[16px] my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">{state.formErrors[key]}</div>
		}

		const defaultDate = new Date()
		const day = String(defaultDate.getDate()).padStart(2, '0')
		const month = String(defaultDate.getMonth() + 1).padStart(2, '0')
		const year = defaultDate.getFullYear()
		const formattedDate = `${month}/${day}/${year}`
		const { visibleFields } = state

		const basicInfo = globalColumnConfigs.filter(column => column.block === 'basic_info' && column.visible)
		const scopeOfDelivery = globalColumnConfigs.filter(column => column.block === 'scope_of_delivery' && column.visible)
		const priceInfo = globalColumnConfigs.filter(column => column.block === 'price_info' && column.visible)
		const marketplaceInfo = globalColumnConfigs.filter(column => column.block === 'marketplace_info' && column.visible)
		const customData = globalColumnConfigs.filter(column => column.block === 'custom_data' && column.visible)

		return (
			<>
				<form action="#" method="POST" onSubmit={this.handleSubmit} className="py-2 pb-10 bg-white">
					<div className="single-product-details grid grid-cols-1 h-screen pb-24 overflow-y-scroll">
						<div className="px-9 py-4">
							<div className="grid grid-flow-row-dense grid-cols-5 gap-6">
								<div className="col-span-3">
									<div className="sticky top-0">
										<div>
											{
												basicInfo.length > 0 && (
													<h2 className="text-md font-bold tracking-wider py-2 border-b">Basic Info</h2>
												)
											}

											{
												basicInfo.map(config => {
													return this.renderInputWithConfig(config, displayError)
												})
											}

											{/* <div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
												<div>Dealer SKU*</div>
												<div>
													<input
														value={state.formData ? state.formData.sku : ''}
														onChange={this.handleChange}
														type="text"
														name="sku"
														placeholder="SKU"
														autoComplete="off"
														className="border-0 m-0 p-0 w-full"
													/>
												</div>
												{displayError('sku')}
											</div> */}

											{/* <div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
												<div>Reference Number*</div>
												<div className="flex relative">
													<ServerAutoSuggest setFormValue={this.setFormValue} formData={state.formData} />
												</div>
												{displayError('reference_number')}
											</div> */}

											{/* <div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
												<div>Brand*</div>
												<div>
													<input
														value={state.formData ? state.formData.brand : ''}
														onChange={e => {
															this.handleChange(e)
															this.handleWarrantyCard(e)
														}}
														autoComplete="off"
														type="text"
														name="brand"
														placeholder="Brand"
														className="border-0 m-0 p-0 w-full"
													/>
												</div>
												{displayError('brand')}
											</div> */}

											{/* {
												visibleFields.includes('series') && (
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Series</div>
														<div>
															<input
																value={state.formData ? state.formData.series : ''}
																onChange={this.handleChange}
																onSelect={this.handleChange}
																autoComplete="off"
																type="text"
																name="series"
																placeholder="Series"
																className="border-0 m-0 p-0 w-full"
															/>
														</div>
														{displayError('series')}
													</div>
												)
											} */}

											{/* {
												visibleFields.includes('serial_number') && (
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Serial Number</div>
														<div>
															<input
																value={state.formData ? state.formData.serial_number : ''}
																onChange={this.handleChange}
																onSelect={this.handleChange}
																type="text"
																name="serial_number"
																placeholder="Serial Number"
																className="border-0 m-0 p-0 w-full"
															/>
														</div>
														{displayError('serial_number')}
													</div>
												)
											} */}

											{/* {
												visibleFields.includes('dial') && (
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Dial</div>
														<div>
															<input
																value={state.formData ? state.formData.dial : ''}
																onSelect={this.handleChange}
																onChange={this.handleChange}
																type="text"
																name="dial"
																placeholder="Dial"
																className="border-0 m-0 p-0 w-full"
															/>
														</div>
														{displayError('dial')}
													</div>
												)
											} */}

											{/* {
												visibleFields.includes('bezel') && (
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Bezel</div>
														<div>
															<input
																value={state.formData ? state.formData.bezel : ''}
																onChange={this.handleChange}
																onSelect={this.handleChange}
																type="text"
																name="bezel"
																placeholder="Bezel"
																className="border-0 m-0 p-0 w-full"
															/>
														</div>
														{displayError('bezel')}
													</div>
												)
											} */}

											{/* {
												visibleFields.includes('bracelet') && (
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Bracelet</div>
														<div>
															<input
																value={state.formData ? state.formData.bracelet : ''}
																onChange={this.handleChange}
																onSelect={this.handleChange}
																type="text"
																name="bracelet"
																placeholder="Bracelet"
																className="border-0 m-0 p-0 w-full"
															/>
														</div>
														{displayError('bracelet')}
													</div>
												)
											} */}

										</div>

										<div>
											{
												scopeOfDelivery.length > 0 && (
													<h2 className="text-md font-bold tracking-wider py-2 border-b">Scope of Delivery</h2>
												)
											}

											{
												scopeOfDelivery.map(config => {
													return this.renderInputWithConfig(config, displayError)
												})
											}


											{/* {
												visibleFields.includes('condition') && (
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Condition</div>
														<div>
															<SelectOptionsFlat
																name={'condition'}
																handleChangeSelect={this.handleChangeSelect}
																selectedValue=""
																options={collections.inventoryConditionsCollection}
															/>
														</div>
														{displayError('condition')}
													</div>
												)
											} */}

											{/* {
												visibleFields.includes('warranty_papers') && (
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Warranty Paper/Card</div>
														<div>
															<SelectOptionsFlat
																name="warranty_papers"
																handleChangeSelect={this.handleChangeSelect}
																selectedValue=""
																options={state.warrantyCardsList}
															/>
														</div>
														{displayError('warranty_papers')}
													</div>
												)
											} */}

											{/* {
												visibleFields.includes('warranty') && (
													this.state.formData?.warranty_papers !== 'no' && (
														<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
															<div>Warranty Date</div>
															<div
																style={{
																	width: '100%',
																	display: 'inline-flex',
																}}
															>
																{
																	<>
																		<div
																			style={{
																				width: '50%',
																			}}
																		>
																			{state.presetPost.warranty_no_date ? (
																				<input
																					defaultValue={'No Date'}
																					value={'No Date'}
																					onChange={this.handleChange}
																					onSelect={this.handleChange}
																					type="text"
																					name="WarrantyDate"
																					placeholder="No Date"
																					className="border-0 m-0 p-0 w-full"
																				/>
																			) : (
																				<DatePickerFlat name="warranty" title="Warranty Date*" handlechangePicker={this.handlechangePicker} defaultValue={null}/>
																			)}
																		</div>

																		<div
																			style={{
																				width: '50% !important',
																				marginLeft: '5px',
																			}}
																		>
																			<input
																				onChange={this.handleChange}
																				onSelect={this.handleChange}
																				style={{
																					opacity: '1',
																					width: '15px',
																				}}
																				type="checkbox"
																				name="warranty_no_date"
																				defaultChecked={state.presetPost.warranty_no_date}
																			/>
																			<span
																				htmlFor="no"
																				className="pl-5"
																				style={{
																					width: '30px !important',
																				}}
																			>
																				No Date
																			</span>
																		</div>
																	</>
																}
															</div>
															{displayError('WarrantyDate')}
														</div>
													)
												)
											} */}

											{/* {
												visibleFields.includes('box') && (
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Box</div>
														<div className="flex gap-4 justify-start">
															<React.Fragment>
																<div className="flex gap-1.5 items-center">
																	<input onChange={this.handleChange} onSelect={this.handleChange} type="radio" value={true} id="yes" name="box" />
																	<label htmlFor="yes">Yes</label>
																</div>
																<div className="flex gap-1.5 items-center">
																	<input onChange={this.handleChange} type="radio" value={false} id="no" name="box" />
																	<label htmlFor="no">No</label>
																</div>
															</React.Fragment>
														</div>
														{displayError('box')}
													</div>
												)
											} */}

											{/* {
												visibleFields.includes('link_count') && (
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Link Count</div>
														<div>
															<input
																value={state.formData ? state.formData.link_count : ''}
																onChange={this.handleChange}
																onSelect={this.handleChange}
																type="text"
																name="link_count"
																placeholder="Link count"
																className="border-0 m-0 p-0 w-full"
															/>
														</div>
														{displayError('link_count')}
													</div>
												)
											} */}

											{/* {
												visibleFields.includes('addition_details') && (
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Additional Details</div>
														<div>
															<textarea
																placeholder="Maximum of 20000 characters."
																maxLength="20000"
																value={state.formData ? state.formData.addition_details : ''}
																onChange={this.handleChange}
																onSelect={this.handleChange}
																type="text"
																name="addition_details"
																className="border-0 m-0 p-0 w-full"
															></textarea>
															<div>
																<small>{!isNil(state.formData.addition_details) ? state.formData.addition_details.length : 0}</small>
																<small>
																	<small>/</small>
																</small>
																<small>
																	<small>20000</small>
																</small>
															</div>
														</div>
														{displayError('addition_details')}
													</div>
												)
											} */}
										</div>

										{
											this.props.view !== 'marketplace' &&
												<div>
													{
														priceInfo.length > 0 && (
															<h2 className="text-md font-bold tracking-wider py-2 border-b">Price Info</h2>
														)
													}

													{
														priceInfo.map(config => {
															return this.renderInputWithConfig(config, displayError)
														})
													}

													{/* {
														visibleFields.includes('msrp_price') && (
															<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
																<div>MSRP Price</div>
																<div>
																	<input
																		value={state.formData.msrp_price}
																		onChange={(e) => this.onChangePrice(e, 'msrp_price')}
																		type="text"
																		name="msrp_price"
																		placeholder="MSRP Price"
																		className="border-0 m-0 p-0 w-full"
																	/>
																	<div className="opacity-75">
																		<small>Only whole numbers</small>
																	</div>
																</div>
															</div>
														)
													} */}

													{/* {
														visibleFields.includes('wholesale_price') && (
															<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
																<div className="whitespace-nowrap">
																	Wholesale/Marketplace Price
																	<Tooltip title="This is the price that will be shown in the Elefta Marketplace for other dealers to see" placement="bottom" arrow>
																		<span className="ml-1 inline-block">
																			<InfoOutlinedIcon fontSize="small" />
																		</span>
																	</Tooltip>
																</div>
																<div>
																	<input
																		value={state.formData.wholesale_price}
																		onChange={(e) => this.onChangePrice(e, 'wholesale_price')}
																		type="text"
																		name="wholesale_price"
																		placeholder="Marketplace Price"
																		className="border-0 m-0 p-0 w-full"
																	/>
																	<div className="opacity-75">
																		<small>Only whole numbers</small>
																	</div>
																</div>
															</div>
														)
													} */}

													{/* {
														visibleFields.includes('cost') && (
															<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
																<div>Cost</div>
																<div>
																	<input
																		value={state.formData.cost}
																		onChange={(e) => this.onChangePrice(e, 'cost')}
																		type="text"
																		name="cost"
																		placeholder="Cost"
																		className="border-0 m-0 p-0 w-full"
																	/>
																	<div className="opacity-75">
																		<small>Only whole numbers</small>
																	</div>
																</div>
															</div>
														)
													} */}

													{/* {
														visibleFields.includes('date_purchased') && (
															<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
																<div>Date Purchased</div>
																<div>
																	<DatePickerFlat
																		name="date_purchased"
																		title="Date Purchased*"
																		handlechangePicker={this.handlechangePicker}
																		defaultValue={formattedDate}

																	/>
																</div>
																{displayError('date_purchased')}
															</div>
														)
													} */}

													{/* {
														visibleFields.includes('vendor') && (
															<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
																<div>Vendor/Partner</div>
																<div>
																	<input
																		value={state.formData ? state.formData.Vendor : ''}
																		onChange={this.handleChange}
																		onSelect={this.handleChange}
																		type="text"
																		name="vendor"
																		placeholder="Vendor"
																		className="border-0 m-0 p-0 w-full"
																	/>
																</div>
																{displayError('Vendor')}
															</div>
														)
													} */}

												</div>
										}
										{
											this.props.view !== 'marketplace' &&
												<div>
													{
														marketplaceInfo.length > 0 && (
															<h2 className="text-md font-bold tracking-wider py-2 border-b">Marketplace Info</h2>
														)
													}

													{
														marketplaceInfo.map(config => {
															return this.renderInputWithConfig(config, displayError)
														})
													}
													{/* <div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Visible in marketplace</div>
														<div className="flex gap-4 justify-start">
															<React.Fragment>
																<div className="flex gap-1.5 items-center">
																	<input onChange={this.handleChange} type="radio" id="yes" value={true} name="visible_in_marketplace" checked={state.formData.visible_in_marketplace === "true"} />
																	<label htmlFor="yes">Yes</label>
																</div>
																<div className="flex gap-1.5 items-center">
																	<input onChange={this.handleChange} type="radio" id="no" value={false} name="visible_in_marketplace" checked={state.formData.visible_in_marketplace === "false"}
																	/>
																	<label htmlFor="no">No</label>
																</div>
															</React.Fragment>
														</div>
														{displayError('visible_in_marketplace')}
													</div> */}

													{/* {
														visibleFields.includes('online_price') && (
															<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
																<div>Website price</div>
																<div>
																	<input
																		value={state.formData.online_price}
																		onChange={(e) => this.onChangePrice(e, 'online_price')}
																		type="text"
																		name="online_price"
																		placeholder="Website price"
																		className="border-0 m-0 p-0 w-full"
																	/>
																	<div className="opacity-75">
																		<small>Only whole numbers</small>
																	</div>
																</div>
															</div>
														)
													} */}

												</div>
										}
										{
											this.props.view !== 'marketplace' &&
												<div>
													{
														customData.length > 0 && (
															<h2 className="text-md font-bold tracking-wider py-2 border-b">Custom Data</h2>
														)
													}
													{/* {state.customColumn.map((column, index) => (
														<div key={index} className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
															<div>{column.label}</div>
															<div>
																{column.field_type === 'boolean' && (
																	<div className="flex gap-4 justify-start" key={column.key}>
																		<React.Fragment>
																			<div className="flex gap-1.5">
																				<input
																					onChange={this.handleChangeCustomColumn}
																					onSelect={this.handleChangeCustomColumn}
																					type="radio"
																					value={true}
																					id={`${column.key}-yes`}
																					name={column.key}
																				/>
																				<label htmlFor={`${column.key}-yes`}>
																					Yes
																				</label>
																			</div>
																			<div className="flex gap-1.5">
																				<input
																					onChange={this.handleChangeCustomColumn}
																					onSelect={this.handleChangeCustomColumn}
																					type="radio"
																					value={false}
																					id={`${column.key}-no`}
																					name={column.key}
																				/>
																				<label htmlFor={`${column.key}-no`}>
																					No
																				</label>
																			</div>
																		</React.Fragment>
																	</div>
																)}

																{column.field_type === 'text' && (
																	<input
																		key={column.key}
																		onChange={this.handleChangeCustomColumn}
																		onBlur={this.handleTrimText}
																		type="text"
																		name={column.key}
																		value={state.formData.custom_column_values?.[column.key]}
																		placeholder={column.label}
																		className="border-0 m-0 p-0 w-full"
																	/>
																)}

																{column.field_type === 'float' && (
																	<input
																		key={column.key}
																		onChange={this.handleChangeCustomColumn}
																		type="number"
																		name={column.key}
																		placeholder={column.label}
																		className="border-0 m-0 p-0 w-full"
																	/>
																)}

																{column.field_type === 'date' && (
																	<DatePickerFlat
																		name={column.key}
																		title={column.label}
																		handlechangePicker={this.handleChangePickerCustomColumn}

																	/>
																)}

																{displayError(column.key)}

																{column.field_type === "list" && (
																	<div onKeyDown={this.handleKeyDownDropDownCustom} className={Classnames("w-3/12 flex items-center justify-start ")}>
																		<button onClick={(event) => this.handleMenuOpen(event, index)} className="flex items-center whitespace-nowrap">
																			{state.formData.custom_column_values?.[column.key] ? state.formData.custom_column_values?.[column.key] : 'Please Select'}
																			<ArrowDropDownIcon />
																		</button>

																		<Menu
																			anchorEl={currentMenuIndex === index ? menuAnchorEl : null}
																			open={Boolean(currentMenuIndex === index)}
																			onClose={this.handleMenuClose}
																			MenuListProps={{
																				style: { padding: 0 },
																			}}
																			sx={{
																				'.MuiMenu-paper': {
																					borderRadius: '5px',
																					border: '1px solid #5D6E81',
																				},
																			}}
																		>
																			<div className="flex items-center relative border-b border-[#5D6E81]">
																				<input
																					className="ps-10 py-2 w-full text-[#5D6E81]" type="text"
																					placeholder="Search..."
																					value={search}
																					onChange={(e) => this.handleSearchChange(e, column)}
																				/>
																				<div className="absolute left-2 text-[#5D6E81]">
																					<SearchIcon className="rotate-90"/>
																				</div>
																			</div>
																			<div className="max-h-[200px] overflow-y-scroll">
																				{currentListOption.map((field, fieldIndex) => (
																					<MenuItem
																						ref={el => {
																							if (fieldIndex === this.state.focusedItemIndex && el) el.focus();
																						}}
																						name={column.key} key={fieldIndex}
																						onClick={() => this.handleChoseOption(field, column)}
																					>
																						<span className="text-[#5D6E81]">{field.value}</span>
																					</MenuItem>
																				))}
																			</div>
																		</Menu>
																	</div>
																)}
															</div>
														</div>
													))} */}

													{
														customData.map(config => {
															return this.renderInputWithConfig(config, displayError)
														})
													}
												</div>
										}
									</div>
								</div>

								<div className="col-span-2">
									<div className="top-0">
										{	this.props.view !== 'marketplace' &&
											<div className="mb-12">
												<h2 className="text-md font-bold tracking-wider py-2">Status</h2>
												<div className="relative border-b grid grid-cols-2 gap-2 py-2 text-sm">
													<div>Status*</div>
													<div>
														<SelectOptionsFlat
															extraValueErrors={state.formErrors.status_details}
															name={'status'}
															handleChangeSelect={this.handleChangeSelect}
															handleChange={this.handleChange}
															showCustomerAutoSuggest={true}
															handleChangeAutoComplete={this.handleChangeAutoComplete}
															selectedValue=""
															options={this.inventoryStatusesCollection()}
														/>
													</div>
													{displayError('status')}
												</div>
											</div>
										}
										{	this.props.view === 'marketplace' &&
											<div className="mb-12">
												<h2 className="text-md font-bold tracking-wider py-2">Price Info</h2>
												<div className="relative border-b grid grid-cols-2 gap-2 py-2 text-sm">
													<div>Marketplace Price</div>
													<div>
														<input
															value={state.formData.wholesale_price}
															onChange={(e) => this.onChangePrice(e, 'wholesale_price')}
															type="text"
															name="wholesale_price"
															placeholder="Price"
															className="border-0 m-0 p-0 w-full"
														/>
													</div>
												</div>
											</div>
										}

										{state.formData.status === 'sold_to' && (
											<>
												<div className="mt-5 mb-4">
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Sold Price*</div>
														<div>
															<input
																defaultValue={state.formData.sold_price}
																onSelect={this.handleChange}
																onChange={this.handleChange}
																type="text"
																name="sold_price"
																placeholder="Sold price"
																className="border-0 m-0 p-0 w-full"
															/>
															{displayError('sold_price')}
														</div>
													</div>
												</div>

												<div className="mt-5 mb-4">
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Sold Date</div>
														<div
															style={{
																width: '100%',
																display: 'inline-flex',
															}}
														>
															<>
																<div>
																	<DatePickerFlat
																		name="sold_date"
																		title="Sold Date"
																		handlechangePicker={this.handlechangePicker}
																		defaultValue={state.formData.sold_date}

																	/>
																</div>
															</>
															{displayError('sold_date')}
														</div>
													</div>
												</div>
											</>
										)}

										<div className="mt-5 mb-4">
											<h2 className="text-md font-bold tracking-wider py-2">Media</h2>
											<UploadMedia
												innerClassNames="gap-5 grid grid-cols-2 my-5 upload-media-container"
												text="Select files or drag and drop"
												multipe={true}
												maximum={40}
												uploadUpdate={this.uploadImages}
											/>
											{displayError('Images')}
										</div>

										<div className="mb-4">
											<h2 className="text-md font-bold tracking-wider py-2">Internal Note</h2>
											<div className="border-b py-2 text-sm">
												<textarea
													placeholder="Maximum of 20000 characters."
													maxLength="20000"
													defaultValue=""
													onChange={this.handleChange}
													onSelect={this.handleChange}
													type="text"
													name="internal_note"
													className="border-0 m-0 p-0 w-full"
												></textarea>
												<div>
													<small>{!isNil(state.formData.internal_note) ? state.formData.internal_note.length : 0}</small>
													<small>
														<small>/</small>
													</small>
													<small>
														<small>20000</small>
													</small>
												</div>
												<div className="opacity-75">
													<small>This will be visible only for your team members.</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* <div className="my-9">{displayError('error')}</div> */}
						<div className="-bottom-2 bg-white flex flex-row justify-between sticky items-end">
							<div className='flex justify-between w-full border-blue-500 border-t-2 px-8'>
								{state.editMode && (
									<ConfirmPopUp onConfirm={this.handleDeleteProduct} title="Confirm deleting product" content="Are you sure you want to delete this product?">
										<button className="px-8 py-3 my-2 lg:my-6 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer bg-red-700 uppercase">
											{state.sendingData && (
												<svg className="inline-block animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													{' '}
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
													<path
														className="opacity-75"
														fill="currentColor"
														d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
													/>
												</svg>
											)}
											Delete Product
										</button>
									</ConfirmPopUp>
								)}
								<button
									type="submit"
									onClick={this.handleSubmit}
									className="px-8 py-3 my-2 lg:my-6 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer bg-blue uppercase"
								>
									{state.sendingData && (
										<svg className="inline-block animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
											{' '}
											<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
											<path
												className="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											/>
										</svg>
									)}
									{state.editMode ? 'Update Product' : 'Add Product'}
								</button>
							</div>
						</div>
					</div>
				</form>
				<CustomMobileModal
					open={open}
					onClose={this.props.handleCloseAddInventory}
					className="bg-[#DEDEDE] md:hidden"
					sx={{
						'& .MuiDialog-paper': {
							width: '100%',
							maxWidth: '100%',
							height: '100%',
							maxHeight: '100%',
						},
					}}
				>
					<form onSubmit={this.handleSubmit}>
						<DialogTitle
							className="bg-[#DEDEDE] rounded-tl-[15px] h-[63px] items-center flex"
						>
							<div className="flex justify-between items-center text-[#5D6E81] w-full">
								<Box className="flex items-center">
									<KeyboardArrowLeftIcon   onClick={props.handleCloseAddInventory}/>
									<span className="font-semibold text-[#5D6E81] capitalize ms-2 text-[30px]">Add Inventory</span>
								</Box>
								<CloseIcon className="cursor-pointer text-black font-bold" onClick={props.handleCloseAddInventory} />
							</div>
						</DialogTitle>
						<DialogContent className="mt-4">
							<div className="font-semibold text-[#5D6E81] mb-2">Basic info</div>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField
										fullWidth
										label="Dealer SKU*"
										variant="outlined"
										name="sku"
										size="medium"
										value={state.formData ? state.formData.sku : ''}
										onChange={this.handleChange}
										inputProps={{style: {fontSize: 14}}}
										sx={{
											label: {color: '#5D6E81'}
										}}
										InputLabelProps={{style: {fontSize: 14}}}

									/>
									{displayError('sku')}
								</Grid>
								<Grid item xs={6}>
									<SelectOptionsFlat
										extraValueErrors={state.formErrors.status_details}
										name={'status'}
										handleChangeSelect={this.handleChangeSelect}
										handleChange={this.handleChange}
										showCustomerAutoSuggest={true}
										handleChangeAutoComplete={this.handleChangeAutoComplete}
										selectedValue=""
										options={this.inventoryStatusesCollection()}
										isMobile={true}
									/>
									{displayError('status')}
								</Grid>
								{state.formData.status === 'sold_to' && (
									<>
										<Grid item xs={6}>
											<TextField
												defaultValue={state.formData.sold_price}
												fullWidth
												label="Sold price"
												variant="outlined"
												name="sold_price"
												size="medium"
												onSelect={this.handleChange}
												onChange={this.handleChange}
												inputProps={{style: {fontSize: 14}}}
												sx={{
													label: {color: '#5D6E81'}
												}}
												InputLabelProps={{style: {fontSize: 14}}}
											/>
											{displayError('sold_price')}
										</Grid>
										<Grid item xs={6}>
											<Box
												sx={{
													border: '1px solid #E0E0E0',
													padding: '14px',
													borderRadius: '5px'
												}}
											>
												<DatePickerFlat
													name="sold_date"
													title="Sold Date"
													handlechangePicker={this.handlechangePicker}
													defaultValue={state.formData.sold_date}
												/>
											</Box>
										</Grid>
									</>
								)}

								<Grid item xs={12}>
									<div className="flex p-[14px] border-[1px] rounded-[5px]">
										<ServerAutoSuggest
											setFormValue={this.setFormValue}
											formData={state.formData}
											isMobile={true}
										/>
									</div>
									{displayError('reference_number')}

								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label="Brand*"
										variant="outlined"
										size="medium"
										value={state.formData ? state.formData.brand : ''}
										onChange={e => {
											this.handleChange(e)
											this.handleWarrantyCard(e)
										}}
										autoComplete="off"
										type="text"
										name="brand"
										InputLabelProps={{
											shrink: state.formData && state.formData.brand ? true : false,
											style: {fontSize: 14}
										}}
										inputProps={{style: {fontSize: 14}}}
										sx={{
											label: {color: '#5D6E81'}
										}}
									/>
									{displayError('brand')}
								</Grid>

								{
									visibleFields.includes('series') && (
										<Grid item xs={12}>
											<TextField
												fullWidth
												label="Series*"
												variant="outlined"
												size="medium"
												value={state.formData ? state.formData.series : ''}
												onChange={this.handleChange}
												onSelect={this.handleChange}
												autoComplete="off"
												type="text"
												name="series"
												InputLabelProps={{
													shrink: state.formData && state.formData.brand ? true : false,
													style: {fontSize: 14}
												}}
												inputProps={{style: {fontSize: 14}}}
												sx={{
													label: {color: '#5D6E81'}
												}}
											/>
											{displayError('series')}
										</Grid>
									)
								}

								{
									visibleFields.includes('serial_number') && (
										<Grid item xs={6}>
											<TextField
												fullWidth
												label="Serial Number"
												variant="outlined"
												size="medium"
												value={state.formData ? state.formData.serial_number : ''}
												onChange={this.handleChange}
												onSelect={this.handleChange}
												type="text"
												name="serial_number"
												inputProps={{style: {fontSize: 14}}}
												InputLabelProps={{
													shrink: state.formData && state.formData.brand ? true : false,
													style: {fontSize: 14}
												}}
												sx={{
													label: {color: '#5D6E81'}
												}}
											/>
											{displayError('serial_number')}
										</Grid>
									)
								}

								{
									visibleFields.includes('dial') && (
										<Grid item xs={6}>
											<TextField
												fullWidth
												label="Dial"
												variant="outlined"
												size="medium"
												value={state.formData ? state.formData.dial : ''}
												onSelect={this.handleChange}
												onChange={this.handleChange}
												name="dial"
												inputProps={{style: {fontSize: 14}}}
												InputLabelProps={{
													shrink: state.formData && state.formData.brand ? true : false,
													style: {fontSize: 14}
												}}
												sx={{
													label: {color: '#5D6E81'}
												}}
											/>
											{displayError('dial')}
										</Grid>
									)
								}

								{
									visibleFields.includes('bezel') && (
										<Grid item xs={6}>
											<TextField
												fullWidth
												label="Bezel"
												variant="outlined"
												size="medium"
												value={state.formData ? state.formData.bezel : ''}
												onChange={this.handleChange}
												onSelect={this.handleChange}
												name="bezel"
												inputProps={{style: {fontSize: 14}}}
												InputLabelProps={{
													shrink: state.formData && state.formData.bezel ? true : false,
													style: {fontSize: 14}
												}}
												sx={{
													label: {color: '#5D6E81'}
												}}
											/>
											{displayError('bezel')}
										</Grid>
									)
								}

								{
									visibleFields.includes('bracelet') && (
										<Grid item xs={6}>
											<TextField
												fullWidth
												label="Bracelet"
												variant="outlined"
												value={state.formData ? state.formData.bracelet : ''}
												onChange={this.handleChange}
												onSelect={this.handleChange}
												name="bracelet"
												size="medium"
												inputProps={{style: {fontSize: 14}}}
												InputLabelProps={{
													shrink: state.formData && state.formData.brand ? true : false,
													style: {fontSize: 14}
												}}
												sx={{
													label: {color: '#5D6E81'}
												}}
											/>
											{displayError('bracelet')}
										</Grid>
									)
								}
							</Grid>

							{
								!Globals.isAllScopeOfDeliveryShown(visibleFields) && (
									<div className="font-semibold text-[#5D6E81] mt-6 mb-2">Scope of Delivery</div>
								)
							}

							<Grid container spacing={2}>
								{
									visibleFields.includes('condition') && (
										<Grid item xs={12}>
											<SelectOptionsFlat
												name={'condition'}
												handleChangeSelect={this.handleChangeSelect}
												selectedValue=""
												options={sortBy(collections.inventoryConditionsCollection, (e) => e.option)}
												isMobile={true}
											/>
											{displayError('condition')}
										</Grid>
									)
								}

								{
									visibleFields.includes('warranty_papers') && (
										<Grid item xs={12}>
											<SelectOptionsFlat
												name="warranty_papers"
												handleChangeSelect={this.handleChangeSelect}
												selectedValue=""
												options={state.warrantyCardsList}
												isMobile={true}
											/>
											{displayError('warranty_papers')}
										</Grid>
									)
								}

								{
									visibleFields.includes('warranty_papers') && (
										<>
											<Grid item xs={7} className='flex items-center p-0'>
												<Box
													sx={{
														border: '1px solid #E0E0E0',
														padding: '14px',
														borderRadius: '5px'
													}}
												>
													{this.state.formData?.warranty_papers !== 'no' && (
														<div className="">
															<div
																style={{
																	width: '160px',
																	display: 'inline-flex',
																}}
															>
																{
																	<>
																		<div>
																			{state.presetPost.warranty_no_date ? (
																				<input
																					defaultValue={'No Date'}
																					value={'No Date'}
																					onChange={this.handleChange}
																					onSelect={this.handleChange}
																					type="text"
																					name="WarrantyDate"
																					placeholder="No Date"
																					className="border-0 m-0 p-0 w-full"
																				/>
																			) : (
																				<DatePickerFlat
																					name="warranty"
																					title="Warranty Date*"
																					handlechangePicker={this.handlechangePicker}
																					defaultValue={null}

																					isMobileInventory={true}
																				/>
																			)}
																		</div>
																	</>
																}
															</div>
															{displayError('WarrantyDate')}
														</div>
													)}
												</Box>
											</Grid>
											<Grid item xs={4} className='flex items-center'>
												{this.state.formData?.warranty_papers !== 'no' && (
													<div className="">
														<div
															style={{
																width: '100%',
																display: 'inline-flex',
															}}
														>
															{
																<>

																	<div
																		style={{
																			width: '50% !important',
																			marginLeft: '5px',
																		}}
																	>
																		<input
																			onChange={this.handleChange}
																			onSelect={this.handleChange}
																			style={{
																				opacity: '1',
																				width: '15px',
																			}}
																			type="checkbox"
																			name="warranty_no_date"
																			defaultChecked={state.presetPost.warranty_no_date}
																		/>
																		<span
																			htmlFor="no"
																			className="pl-5 text-[14px] text-[#5D6E81]"
																			style={{
																				width: '30px !important',
																			}}
																		>
																			No Date
																		</span>
																	</div>
																</>
															}
														</div>
													</div>
												)}
											</Grid>
										</>
									)
								}

								{
									visibleFields.includes('box') && (
										<Grid item xs={6}>
											<Box
												sx={{
													border: '1px solid #E0E0E0',
													height: '56px',
													padding: '5px 14px',
													borderRadius: '5px',
													fontSize: 14
												}}
											>
												<div className="text-[#5D6E81]">Box</div>
												<div className="flex gap-8">
													<span>
														<input onChange={this.handleChange} onSelect={this.handleChange} type="radio" value={true} id="yes" name="box" />
														<label className="text-[#5D6E81] ml-1" htmlFor="yes">Yes</label>
													</span>
													<span>
													<input onChange={this.handleChange} type="radio" value={false} id="no" name="box" />
														<label className="text-[#5D6E81] ml-1" htmlFor="no">No</label>
													</span>
												</div>
												{displayError('box')}
											</Box>
										</Grid>
									)
								}

								{
									visibleFields.includes('link_count') && (
										<Grid item xs={6}>
											<TextField
												fullWidth
												label="Link Count"
												variant="outlined"
												size="medium"
												value={state.formData ? state.formData.link_count : ''}
												onChange={this.handleChange}
												onSelect={this.handleChange}
												name="link_count"
												inputProps={{style: {fontSize: 14}}}
												InputLabelProps={{
													style: {fontSize: 14}
												}}
												sx={{
													label: {color: '#5D6E81'}
												}}
											/>
											{displayError('link_count')}
										</Grid>
									)
								}

								{
									visibleFields.includes('box') && (
										<Grid item xs={12}>
											<TextField
												fullWidth
												label="Additional Details"
												variant="outlined"
												size="medium"
												value={state.formData ? state.formData.addition_details : ''}
												onChange={this.handleChange}
												onSelect={this.handleChange}
												name="addition_details"
												inputProps={{style: {fontSize: 14}}}
												InputLabelProps={{
													style: {fontSize: 14}
												}}
												sx={{
													label: {color: '#5D6E81'}
												}}
											/>
											{displayError('addition_details')}
										</Grid>
									)
								}
							</Grid>

							{
								this.props.view !== 'marketplace' &&
								<>
									{
										!Globals.isAllPriceInfoShown(visibleFields) && (
											<div className="font-semibold text-[#5D6E81] mt-6 mb-2">Price Info</div>
										)
									}
									<Grid container spacing={2}>
										{
											visibleFields.includes('msrp_price') && (
												<Grid item xs={6}>
													<TextField
														fullWidth
														label="MSRP Price"
														variant="outlined"
														size="medium"
														value={state.formData.msrp_price}
														onChange={(e) => this.onChangePrice(e, 'msrp_price')}
														name="msrp_price"
														inputProps={{style: {fontSize: 14}}}
														InputLabelProps={{
															style: {fontSize: 14}
														}}
														sx={{
															label: {color: '#5D6E81'}
														}}
													/>
													{displayError('msrp_price')}
												</Grid>
											)
										}

										{
											visibleFields.includes('online_price') && (
												<Grid item xs={6}>
													<TextField
														fullWidth
														label="Online Price"
														variant="outlined"
														size="medium"
														value={state.formData.online_price}
														onChange={(e) => this.onChangePrice(e, 'online_price')}
														name="online_price"
														inputProps={{style: {fontSize: 14}}}
														InputLabelProps={{
															style: {fontSize: 14}
														}}
														sx={{
															label: {color: '#5D6E81'}
														}}
													/>
													{displayError('online_price')}
												</Grid>
											)
										}

										{
											visibleFields.includes('cost') && (
												<Grid item xs={6}>
													<TextField
														fullWidth
														label="Cost"
														variant="outlined"
														size="medium"
														value={state.formData.cost}
														onChange={(e) => this.onChangePrice(e, 'cost')}
														name="cost"
														inputProps={{style: {fontSize: 14}}}
														InputLabelProps={{
															style: {fontSize: 14}
														}}
														sx={{
															label: {color: '#5D6E81'}
														}}
													/>
													{displayError('cost')}
												</Grid>
											)
										}

										{
											visibleFields.includes('date_purchased') && (
												<Grid item xs={6}>
													<Box
														sx={{
															border: '1px solid #E0E0E0',
															padding: '14px',
															borderRadius: '5px'
														}}
													>
														<DatePickerFlat
															name="date_purchased"
															title="Date Purchased*"
															handlechangePicker={this.handlechangePicker}
															defaultValue={formattedDate}
															isMobileInventory={true}
														/>
														{displayError('date_purchased')}
													</Box>
												</Grid>
											)
										}

										{
											visibleFields.includes('vendor') && (
												<Grid item xs={12}>
													<TextField
														fullWidth
														label="Vendor/Partner"
														variant="outlined"
														size="medium"
														value={state.formData ? state.formData.Vendor : ''}
														onChange={this.handleChange}
														onSelect={this.handleChange}
														name="vendor"
														inputProps={{style: {fontSize: 14}}}
														InputLabelProps={{
															style: {fontSize: 14}
														}}
														sx={{
															label: {color: '#5D6E81'}
														}}
													/>
													{displayError('vendor')}
												</Grid>
											)
										}
									</Grid>
								</>
							}

							<div className="font-semibold text-[#5D6E81] mt-6 mb-2">Marketplace Info</div>
							<Grid container spacing={2}>
								{
									this.props.view !== 'marketplace' &&
									<Grid item xs={6}>
										<Box
											sx={{
												border: '1px solid #E0E0E0',
												height: '56px',
												padding: '5px 14px',
												borderRadius: '5px'
											}}
										>
											<div className="text-[#5D6E81] text-[10px]">Visible in Marketplace*</div>
											<div className="flex gap-8">
												<span>
													<input onChange={this.handleChange} type="radio" id="yes" value={true} name="visible_in_marketplace" checked={state.formData.visible_in_marketplace === "true"} />
													<label className="text-[#5D6E81] ml-1" htmlFor="yes">Yes</label>
												</span>
												<span>
													<input onChange={this.handleChange} type="radio" id="no" value={false} name="visible_in_marketplace" checked={state.formData.visible_in_marketplace === "false"}/>
													<label className="text-[#5D6E81] ml-1" htmlFor="no">No</label>
												</span>
											</div>
											{displayError('visible_in_marketplace')}
										</Box>
									</Grid>
								}

								{
									visibleFields.includes('wholesale_price') && (
										<Grid item xs={6}>
											<TextField
												fullWidth
												label="Marketplace Price"
												variant="outlined"
												size="medium"
												value={state.formData.wholesale_price}
												onChange={(e) => this.onChangePrice(e, 'wholesale_price')}
												name="wholesale_price"
												inputProps={{style: {fontSize: 14}}}
												InputLabelProps={{
													style: {fontSize: 14}
												}}
												sx={{
													label: {color: '#5D6E81'}
												}}
											/>
											{displayError('wholesale_price')}
										</Grid>
									)
								}
							</Grid>
							{
								this.props.view !== 'marketplace' &&
								<>
									<div className="font-semibold text-[#5D6E81] mt-6 mb-2">Custom Data</div>
									{state.customColumn.map((column, index) => (
										<Grid key={index} item xs={12}>
											{column.field_type === 'boolean' &&
												<Box
													sx={{
														border: '1px solid #E0E0E0',
														height: '56px',
														padding: '5px 14px',
														borderRadius: '5px',
														mt: 2,
													}}
												>
													<div className="text-[#5D6E81] text-[10px]">{column.label}</div>
													<div className="flex gap-8">
														<span>
															<input
																id={`${column.key}-yes`}
																name={column.key}
																onChange={this.handleChangeCustomColumn}
																onSelect={this.handleChangeCustomColumn}
																type="radio" value={true}
															/>
															<label className="text-[#5D6E81] ml-1" htmlFor={`${column.key}-yes`}>Yes</label>
														</span>
														<span>
															<input
																onChange={this.handleChangeCustomColumn}
																onSelect={this.handleChangeCustomColumn}
																type="radio"
																value={false}
																id={`${column.key}-no`}
																name={column.key}
															/>
															<label className="text-[#5D6E81] ml-1" htmlFor={`${column.key}-no`}>No</label>
														</span>
													</div>
												</Box>
											}
											{column.field_type === 'text' &&
												<TextField
													fullWidth
													label={column.label}
													variant="outlined"
													size="medium"
													onChange={this.handleChangeCustomColumn}
													onBlur={this.handleTrimText}
													type="text"
													name={column.key}
													placeholder={column.label}
													inputProps={{style: {fontSize: 14}}}
													InputLabelProps={{
														style: {fontSize: 14}
													}}
													sx={{
														mt: 2,
														label: {color: '#5D6E81'},
													}}
												/>
											}
											{column.field_type === 'float' &&
												<TextField
													fullWidth
													label={column.label}
													variant="outlined"
													size="medium"
													onChange={this.handleChangeCustomColumn}
													type="number"
													name={column.key}
													inputProps={{style: {fontSize: 14}}}
													InputLabelProps={{
														style: {fontSize: 14}
													}}
													sx={{
														mt: 2,
														label: {color: '#5D6E81'},
													}}
												/>
											}
											{column.field_type === 'date' && (
												<Box
													sx={{
														border: '1px solid #E0E0E0',
														padding: '14px',
														borderRadius: '5px',
														mt: 2,
													}}
												>
													<DatePickerFlat
														name={column.key}
														title={column.label}
														handlechangePicker={this.handleChangePickerCustomColumn}

													/>
												</Box>
											)}
										</Grid>
										))}
								</>

							}
							<div className="font-semibold text-[#5D6E81] mt-6 mb-2">Internal Notes</div>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										placeholder="This will be visible only for your team members."
										variant="outlined"
										size="medium"
										multiline
										rows={2}
										onChange={this.handleChange}
										onSelect={this.handleChange}
										name="internal_note"
										inputProps={{style: {fontSize: 14, color: '#5D6E81'}}}
										InputLabelProps={{
											style: {fontSize: 14}
										}}
									/>
								</Grid>
							</Grid>

							<div className="font-semibold text-[#5D6E81] mt-6">Media</div>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Box
										textAlign="center"
									>
										<UploadMedia
											innerClassNames="gap-5 grid grid-cols-2 my-5 upload-media-container"
											text="Add Photos"
											multipe={true}
											maximum={40}
											uploadUpdate={this.uploadImages}
										/>
									</Box>
								</Grid>
							</Grid>

							<Grid container spacing={2} className="mt-12 mb-2">
								<Grid item xs={12}>
									<Stack
										direction="row"
										className="mx-auto"
										spacing={2}
										justifyContent={'center'}
									>
										<Button
											variant="outlined"
											color="inherit"
											sx={{ textTransform: 'none' }}
											className="w-[106px] h-[40px] whitespace-nowrap normal-case"
										>
											Clear all
										</Button>
										<Button
											color="primary"
											variant="contained"
											sx={{ textTransform: 'none' }}
											className="w-[106px] h-[40px] normal-case whitespace-nowrap"
											type="submit"
										>
											Add Product
										</Button>
									</Stack>
								</Grid>
							</Grid>
						</DialogContent>
					</form>
				</CustomMobileModal>
			</>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		globalColumnConfigs: state.globalColumnConfigs,
		currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
	addToast,
	handleGetGlobalColumnConfigs
}

export default connect(mapStateToProps, mapActionsToProps)(AddInventoryComponent)
