import {useState, useEffect} from 'react'
import {Globals} from '../Context'
import {useSelector, connect} from 'react-redux'
import Privacy from '../Components/Privacy'
import TermsOfUse from './TermsOfUse'
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import {addToast, logOut} from './../app/appActions'
import AppcuesService from '../services/AppcuesService'
import {checkAndHandleSingleSpace, updateCredentialsFromResponse} from '../utils/UserSessionHelper'
import Input from '../Components/Account/Input'
import BannerWarning from '../Components/BannerWarning'
import isEmpty from 'lodash/isEmpty'

const Login = props => {
	const {loggedin} = useSelector(state => state),
		navigate = useNavigate(),
		[complementaryData, setComplementaryData] = useState({}),
		[formData, updateFormData] = useState({}),
		[loading, setLoading] = useState(false),
		[formErrors, updateFormErrors] = useState({})


	const {token} = useParams()
	const {continuous} = props
	const [isVerifyingOTP, setIsVerifyingOTP] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [isAcceptedTos, setIsAcceptedTos] = useState(false)

	const handleChange = e => {
		let value = e.target.value.trim()
		if(e.target.name === 'email') {
			value = value.toLowerCase()

			if(!value && formErrors.error === "You have to confirm your email address before continuing.") {
				updateFormErrors({})
			}
		}
		updateFormData({
			...formData,
			[e.target.name]: value,
		})
	}

	const handleSubmit = async (e, acceptToc) => {
		setLoading(true)
		Globals.Cache.deleteCookie(Globals.USERPROFILE)
		Globals.Cache.deleteCookie(Globals.USERSELECTEDSPACE)
		Globals.Cache.deleteCookie(Globals.REFRESH_TOKEN)
		Globals.Cache.deleteStorage(Globals.USERPROFILE)
		Globals.Cache.deleteStorage(Globals.USERSELECTEDSPACE)
		Globals.Cache.deleteStorage(Globals.REFRESH_TOKEN)
		e.preventDefault()
		let errors = {}
		if (continuous && loggedin) {
			//This user is already logged in and got an invitation

			return Globals.Axios()
				.post(`${Globals.wsurl}/user/space/send/invitaion/accept/direct/finish`, {
					token: token,
					deviceId: Globals.deviceId,
				})
				.then(response => {
					setLoading(false)
					if (!response.data.status) {
						return props.addToast({
							title: 'Space invitation',
							text: 'Unable to complete your request.',
							type: 'error',
						})
					}

					if (response.data.status && response.data.token) {
						Globals.Cache.set(Globals.TokenKey, response.data.token)
						Globals.Cache.setCookie(Globals.TokenKey, response.data.token)
						return navigate('/inventory')
					}

					errors = {...errors, error: response.data.errors}
					return updateFormErrors(errors)
				})
				.catch(error => {
					setLoading(false)
					errors = {
						...errors,
						error: 'Something went wrong please try again later.',
					}
					updateFormErrors(errors)
					return props.addToast({
						title: 'Space invitation',
						text: 'Unable to complete your request.',
						type: 'error',
					})
				})
		}

		if (isEmpty(formData.email) || !Globals.validateEmail(formData.email)) errors = {...errors, email: 'Your email is required'}
		if (isEmpty(formData.password)) errors = {...errors, password: 'Invalid password'}

		 updateFormErrors(errors)

		if (!isEmpty(errors)) return setLoading(false)

		if (!isEmpty(token) && continuous && !isEmpty(complementaryData)) {
			//This means the user is about to finish the account registration, and join a space straigh away.
			return Globals.Axios()
				.post(`${Globals.wsurl}/user/signin/invitaion/accept/finish`, {
					token: token,
					data: formData,
				})
				.then(response => {
					if (response.data.status && response.data.token) {
						Globals.Cache.set(Globals.TokenKey, response.data.token)
						Globals.Cache.setCookie(Globals.TokenKey, response.data.token)
						setLoading(false)
						return window.location.reload()
					}
					setLoading(false)
					errors = {...errors, error: response.data.errors}
					return updateFormErrors(errors)
				})
				.catch(error => {
					errors = {
						...errors,
						error: 'Something went wrong please try again later.',
					}
					setLoading(false)
					return updateFormErrors(errors)
				})
		}
		let isResendButton = e.currentTarget.type === 'button' && e.currentTarget.name === 'resend-otp'

		if (isResendButton) {
			formData['send_otp'] = true;
		} else {
			formData['send_otp'] = e?.nativeEvent.submitter?.name === 'send-otp'
		}

		if(!!acceptToc) {
			formData['accepted_tos'] = true;
			setIsAcceptedTos(false)
		}

		//Make sure all the data is being correct.
		return Globals.New_Axios({logOutWhenUnauthorized: false})
			.post(`${Globals.NEW_API_URL}/sign_in`, {
				token: token,
				user: formData,
				device_code: Globals.deviceId,
			}, {
                headers: {
                    Authorization: null
                }
             })
			.then(response => {
				const {data} = response;
				if (data.response_code === "sent_otp") {
					setIsVerifyingOTP(true)
					setLoading(false)
					if (isResendButton){
						return props.addToast({
							title: 'Send OTP',
							text: 'OTP have been send successfully',
							type: 'success',
						})
					}
				} else {
					updateCredentialsFromResponse(response)

					Globals.Cache.set(Globals.USERPROFILE, JSON.stringify(response.data))
					checkAndHandleSingleSpace(data);
				}
			})
			.catch(error => {
				if(error?.response?.data?.type === 'has_not_accepted_tos' && error?.response?.status === 401) {
					setIsAcceptedTos(true)
					setLoading(false)
					props.addToast({
						title: 'Error',
						text: error.response.data.message,
						type: 'error',
					})
				}
				const errorMsg = isVerifyingOTP ? "Invalid OTP" : error?.response?.data?.message;
				errors = { ...errors, error: errorMsg };
				setLoading(false)
				return updateFormErrors(errors);
			})
	}

	const handleResendEmail = () => {
		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/confirmation`, {
				user: {
					email: formData.email,
				}
			})
			.then(response => {
				if(response.status === 201) {
					return props.addToast({
						title: 'Resend E-mail',
						text: "E-mail have been resend successfully",
						type: 'success',
					})
				}
			})
			.catch(error => {
			})
	}

	const displayError = key => {
		const confirmationMessage = "You have to confirm your email address before continuing."
		if (!isEmpty(formErrors[key])) return <div className="my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">
			<span className='mr-1'>{formErrors[key]}</span>
			{formErrors[key] === confirmationMessage &&
				<span onClick={handleResendEmail} className='cursor-pointer text-[#2C79E0] underline'>Resend E-mail</span>
			}
		</div>
	}

	useEffect(() => {
		if (loggedin && !continuous) return navigate('/inventory')

		AppcuesService.reset()
	})

	useEffect(() => {
		if (loggedin && isEmpty(Globals.Cache.get(Globals.USERPROFILE))) {
			props.logOut()
		}

		const loadFingerprint = async () => {
			const FingerprintJS = await import('@fingerprintjs/fingerprintjs');
			const fp = await FingerprintJS.load();
			const result = await fp.get();

			return result.visitorId;
		}

		loadFingerprint().then(result => {
			Globals.deviceId = result
		}).catch(error => {
            console.error('Error loading fingerprint:', error);
        });

		const urlParams = new URLSearchParams(window.location.search)
		const confirmed = urlParams.get('confirmed')
		if (confirmed === 'true') {
			props.addToast({
			  title: 'Email Confirmed',
			  text: 'Thank you for confirming your email!',
			  type: 'success',
			});

			urlParams.delete('confirmed');
			const newUrl =
			  window.location.pathname +
			  (urlParams.toString() ? '?' + urlParams.toString() : '');
			window.history.replaceState({}, '', newUrl);
		  }

	}, [])

	useEffect(() => {
		if (!isEmpty(token) && continuous) {
			setLoading(true)
			//Load the token data.

			Globals.New_Axios()
				.post(`${Globals.NEW_API_URL}/v1/find_invited_user/${token}`)
				.then(async response => {
					setLoading(false)
					updateFormData({
						email: response.data.email,
					})

					return setComplementaryData({
						user: response.data.user,
						space: response.data.space,
					})
				})
				.catch(error => {
					setLoading(false)
					//Display error message.
					return props.addToast({
						title: 'Space invitation',
						text: 'Something went wrong please try again later.',
						type: 'error',
					})
				})
		}
	}, [props, continuous, token])

	return (
		<>
			<BannerWarning/>
			<div className="flex md:flex-row flex-col-reverse w-screen sm:h-auto mpt-5 lg:pt-0 lg:h-screen bgLinearBlue md:!bg-none md:!bg-[#2C79E0] h-screen-minus-warning">
				{loading && (
					<div className="bg-white/50 bottom-0 fixed flex items-center justify-center left-0 right-0 rounded-lg top-0 z-50">
						<div role="status">
							<svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
									fill="currentColor"
								/>
								<path
									d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
									fill="currentFill"
								/>
							</svg>
						</div>
					</div>
				)}

				<div className="md:w-2/3 flex flex-col items-center justify-center bgLogin md:pb-0 pb-16 md:pt-0 pt-10">
					<div className="flex flex-col font-bold items-center md:items-start text-xl md:w-96">
						{(() => {
							if (!isEmpty(token) && continuous && !isEmpty(complementaryData)) {
								return (
									<div>
										<h1 className="mb-2">Hey there!</h1>
										<div
											className="font-normal text-sm"
											dangerouslySetInnerHTML={{
												__html: `You are about to join the space <strong>${complementaryData.space.name}</strong> if you don't recognize this invitation you can cancel by clicking <a href="/"><u>here</u></a>`,
											}}
										></div>
									</div>
								)
							} else {
								return (
									<div>
										<h1 className="sm:text-center 2xl:text-left w-96 text-2xl">
											Welcome back!
											<br /> Please login to your account.
										</h1>
										<h3 className="font-normal md:pt-8 pt-4 text-sm tracking-wider sm:text-center 2xl:text-left">
											Don't have an account ?{' '}
											<NavLink to={'/Register'}>
												{' '}
												<strong className="text-blue-brand font-semibold">Sign Up Here!</strong>
											</NavLink>
										</h3>
									</div>
								)
							}
						})()}
					</div>
					<form className="mt-5 md:w-96 w-96" onSubmit={handleSubmit}>
						{!loggedin && !isVerifyingOTP && (
							<>
								<div className="grid grid-cols-1">
									<div className="mt-2">
										<label htmlFor="email" className="2xl:block sm:hidden text-base bg-transparent font-normal py-2">
											Email*
										</label>
										<div className="outlin relative w-full">
											{(() => {
												if (!isEmpty(token) && continuous && !isEmpty(complementaryData)) {
													return (
														<input
															type="email"
															name="email"
															placeholder="Email Address"
															className="sm:rounded-t-lg 2xl:rounded-md border font-medium block p-4 w-full bg-white appearance-none text-gray-500 text-sm"
															disabled
															defaultValue={complementaryData.user.email}
														/>
													)
												} else {
													return (
														<input
															type="email"
															name="email"
															placeholder="Email Address"
															className="sm:rounded-t-lg 2xl:rounded-md border font-medium block p-4 w-full bg-white appearance-none text-black text-sm"
															onChange={handleChange}
														/>
													)
												}
											})()}
										</div>
										{displayError('email')}
									</div>
									<div>
										<div className="outlin w-full">
											<label htmlFor="password" className=" 2xl:block sm:hidden text-base bg-transparent font-normal py-2">
												Password*
											</label>
											<Input
												disableDefaultClass={true}
												type="password"
												name="password"
												placeholder="Password"
												isPassword={true}
												onchange={handleChange}
												showPassword={showPassword}
												handleSetShowPassWord={() => setShowPassword(!showPassword)}
												disableTitle={true}
												className="sm:rounded-b-lg 2xl:rounded-md border font-medium block p-4 pr-[50px] w-full bg-white appearance-none outline-none text-black text-sm"
											/>
										</div>
										{displayError('password')}
									</div>
								</div>
								<div className="flex justify-between my-3">
									<fieldset>
										<div className="relative flex gap-x-3 items-center">
											<div className="flex h-4 w-4 items-center">
												<input id="remeberme" name="comments" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
											</div>
											<div className="text-sm leading-6">
												<label htmlFor="comments" className="font-medium text-xs text-gray-600">
													Remember Me
												</label>
											</div>
										</div>
									</fieldset>
									<NavLink to={'/password-setting'}>
										{' '}
										<strong className="text-blue-brand font-semibold text-xs">Forgot Your Password?</strong>
									</NavLink>
								</div>
							</>
						)}
						{!loggedin && isVerifyingOTP && (
							<>
								<div className="grid grid-cols-1">
									<div className="mt-2">
										<label htmlFor="otp" className="2xl:block sm:hidden text-base bg-transparent font-normal py-2">
											OTP
										</label>
										<div className="outlin relative w-full">
											<input
												type="text"
												name="otp_attempt"
												placeholder="OTP"
												className="sm:rounded-t-lg 2xl:rounded-md border font-medium block p-4 w-full bg-white appearance-none text-black text-sm"
												onChange={handleChange}
											/>
										</div>
									</div>
								</div>
								<div className="flex justify-between my-3">
									<fieldset>
										<div className="relative flex gap-x-3 items-center">
											<div className="flex h-4 w-4 items-center">
												<input
													id="trustedDevice"
													name="enable_trusted_device"
													type="checkbox"
													className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
													onChange={(e) => {
														updateFormData({
															...formData,
															[e.target.name]: e.target.checked,
														})
													}}
												/>
											</div>
											<div className="text-sm leading-6">
												<label htmlFor="trustedDevice" className="font-medium text-sm text-gray-600">
													Trust this device for 30 days
												</label>
											</div>
										</div>
									</fieldset>
									<button type='button' name="resend-otp" onClick={handleSubmit}>
										{' '}
										<strong class="text-blue-brand font-semibold text-xs">Resend OTP</strong>
									</button>
								</div>
							</>
						)}

						<div className="mt-4 text-center">{displayError('error')}</div>
						<div className="w-full mt-4">
							{!loggedin && isVerifyingOTP && (
								<>

									<button className="w-full p-4 rounded text-white my-3 mt-5 cursor-pointer bg-[#2C79E0] font-medium" type="submit" name='verifyOtp' disabled={loading}>
										Verify OTP
									</button>
								</>
							)}
							{!loggedin && !isVerifyingOTP && (
								<>

									<button className="w-full p-4 rounded text-white my-3 mt-5 cursor-pointer bg-[#2C79E0] font-medium" type="submit" name="send-otp" disabled={loading}>
										Login
									</button>
								</>
							)}
							{loggedin && continuous && (
								<button className="w-full p-4 rounded text-white cursor-pointer bg-blue font-medium uppercase" type="submit" disabled={loading}>
									Accept the invitation
								</button>
							)}
						</div>
					</form>
					<Privacy />
					{
						isAcceptedTos && <TermsOfUse hideTerms={() => setIsAcceptedTos(false)} formData={formData} updateFormData={updateFormData}  showButtons={true} handleSubmit={handleSubmit}/>
					}
				</div>
				<div className="md:w-1/3 md:flex hidden flex-col  justify-center text-white px-16">
					<h1 className="text-5xl font-bold">ELEFTA</h1>
					<p className="font-medium leading-7 text-sm pt-10">
						The most advanced watch-specific inventory system and software <br />
						<br />
						Built by watch dealers for watch dealers
					</p>
				</div>
				<div className="md:hidden flex items-center justify-center mt-3 mb-3">
					<img src="/logo-trans-white.png" className=" relative" width="200" height={"auto"} alt="Elefta" loading="lazy" />
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state, props) => {
	return {}
}

const mapActionsToProps = {
	addToast,
	logOut
}

export default connect(mapStateToProps, mapActionsToProps)(Login)
