import Cache from './utils/Storage.jsx'
import Fingerprint from 'fingerprintjs'
import Axios from 'axios'
import moment from 'moment'

import {store} from './app/store'
import {logOut, refreshToken} from './app/appActions'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import intersection from 'lodash/intersection'

let heroku_url = `https://${process.env.HEROKU_APP_NAME}.herokuapp.com`
let wsurl = process.env.API_URL
let NEW_API_URL = process.env.NEW_API_URL
let ACTION_CABLE_URL = process.env.ACTION_CABLE_URL
let ENDPOINTVERSION = 'v1'

let ssurl = process.env.HEROKU_APP_NAME ? heroku_url : process.env.BASE_URL

let TokenKey = '_elefta_ugid',
	New_TokenKey = '_elefta_token_header',
	REFRESH_TOKEN = '_elefta_refresh_token',
	USERPROFILE = '_elefta_user_profile',
	USERSELECTEDSPACE = '_elefta_user_selected_space',
	New_TokenKey_Expiry = '_elefta_token_header_expiry',
	REFRESH_TOKEN_EXPIRY = '_elefta_refresh_token_expiry',
	Sensitive_Data_Column = 'sensitive_data_column',
	SELECTEDSPACEID = !isNull(Cache.get(USERSELECTEDSPACE)) ? JSON.parse(Cache.get(USERSELECTEDSPACE)).id : '',
	fingerprint = new Fingerprint().get(),
	PUBLIC_KEY_STRIPE = process.env.PUBLIC_KEY_STRIPE

Element.prototype.parents = function (selector) {
	try {
		let target = this
		let element = null
		while ((target = target.parentElement) !== null) {
			if (target.classList.contains(selector) && target.nodeType === Node.ELEMENT_NODE) {
				element = target
				break
			}
		}

		return element
	} catch (e) {
		return null
	}
}

const convertToMoney = value => {
	return value.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	})
}

const moneyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
})

const formatCurrency = (amount, currencySign = false) => {
	if (amount === null || amount === undefined) {
		return ''
	}
	let _amount = isNaN(amount) ? amount.toString().replaceAll(',', '') : amount

	if (isNaN(_amount)) {
		return ''
	}
	const moneyFormatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	if (currencySign) return moneyFormatter.format(_amount)
	else return moneyFormatter.format(_amount).replace('.00', '').replace('$', '')
}

const formatPhoneNumber = target => {
	const input = target.replace(/\D/g, '').substring(0, 10) // First ten digits of input only
	const zip = input.substring(0, 3)
	const middle = input.substring(3, 6)
	const last = input.substring(6, 10)

	if (input.length > 6) {
		return `(${zip}) ${middle} - ${last}`
	} else if (input.length > 3) {
		return (target = `(${zip}) ${middle}`)
	} else if (input.length > 0) {
		return (target = `(${zip}`)
	}
}
const validateEmail = email => email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
const keyboardButton = {
	arrowDown: 'ArrowDown',
	arrowRight: 'ArrowRight',
	arrowLeft: 'ArrowLeft',
	arrowUp: 'ArrowUp',
	escape: 'Escape',
	enter: 'Enter',
}
const inventoryViewStatus = ['memo_to', 'pending_sale_to', 'sold_to']
const saleTerms =  [
	{value: 'cod', label: 'C.O.D.'},
	{value: 'net_3', label: 'Net 3 Days'},
	{value: 'net_7', label: 'Net 7 Days'},
	{value: 'net_30', label: 'Net 30 Days'},
	{value: 'custom', label: 'Custom'},
]
const inventorySaleStatus = ['pending_sale_to', 'sold_to']
const inventoryMemoStatus = ['memo_to']
const inventoryStatuses = {
	on_hand: 'On Hand',
	transit_from: 'Transit from',
	memo_to: 'Memo to',
	on_hold_for: 'On Hold for',
	pending_sale_to: 'Pending Sale to',
	sold_to: 'Sold to',
	repair_with: 'Repair with',
}
const warrantyCardsList = {
	rolex: [
		{value: 'no', option: 'No'},
		{value: 'papers', option: 'Papers'},
		{value: 'card_old_green', option: 'Card (Old Green)'},
		{value: 'new_style_card_white', option: 'New Style Card (White)'},
		{value: 'new_style_card_green', option: 'New Style Card (green)'},
		{value: 'service_papers', option: 'Service Papers'},
		{value: 'manuals_only', option: 'Manuals Only'},
		{value: 'certified_pre_owned_card', option: 'Certified Pre Owned Card'}
	],
	other: [
		{value: 'no', option: 'No'},
		{value: 'papers', option: 'Papers'},
		{value: 'card', option: 'Card'},
		{value: 'archives', option: 'Archives'},
		{value: 'manuals_only', option: 'Manuals Only'},
		{value: 'service_card', option: 'Service Card'},
		{value: 'electronic_papers', option: 'Electronic Papers'},
	],
	patek: [
		{value: 'no', option: 'No'},
		{value: 'papers', option: 'Papers'},
		{value: 'archives', option: 'Archives'},
		{value: 'manuals_only', option: 'Manuals Only'},
	],
	ap: [
		{value: 'no', option: 'No'},
		{value: 'papers', option: 'Papers'},
		{value: 'archives', option: 'Archives'},
		{value: 'card', option: 'Card'},
		{value: 'service_card', option: 'Service Card'},
		{value: 'manuals_only', option: 'Manuals Only'},
	],
}
const watchBrands = {
	AM: 'Angular Momentum',
	AP: 'Audemars Piguet',
	AS: 'Alain Silberstein',
	BG: 'Breguet',
	BM: 'Baume et Mercier',
	BP: 'Blancpain',
	BC: 'BOUCHERON',
	BR: 'Breitling',
	BV: 'Bvlgari',
	CC: 'Concord',
	CD: 'Christian Dior',
	CH: 'Chanel',
	CK: 'Calvin Klein',
	CM: 'Corum',
	CO: 'Chronoswiss',
	CP: 'Chopard',
	CR: 'Cartier',
	CT: 'Certina',
	CU: 'Chaumet',
	DJR: 'Daniel Jean-Richard',
	DR: 'Daniel Roth',
	EB: 'Ebel',
	ET: 'Eterna',
	EP: 'Eduard Piguet',
	FM: 'Franck Muller',
	FE: 'Ferrari Panerai',
	GH: 'Glasshuette',
	GP: 'Girard-Perregaux',
	GR: 'GRAHAM',
	GU: 'Gucci',
	HE: 'Hermes',
	HMC: 'H. Moser & Cie',
	HU: 'Hublot',
	HW: 'HARRY WINSTON',
	IWC: 'IWC',
	JLC: 'Jaeqer-LeCoultre',
	LG: 'Longines',
	LS: 'A. Lange & Sohne',
	LV: 'LOUIS VUITTON',
	MB: 'Montblanc',
	MD: 'Mondaine',
	ML: 'Maurice Lacroix',
	MO: 'Movado',
	MV: 'Movado Vizio',
	OM: 'Omega',
	OP: 'Panerai',
	OR: 'Oris',
	PC: 'Paul Picot',
	PD: 'Porsche Design',
	PG: 'Piaget',
	PL: 'Philippe Charriol',
	PP: 'Patek Philippe',
	RD: 'Rado',
	RM: 'Richard Mille',
	RT: 'Revue Thommen',
	RW: 'Raymond Weil',
	RX: 'Rolex',
	RXC: 'Rolex Cellini',
	RDU: 'ROGER DUBUIS',
	SE: 'Seiko',
	TCo: 'Tiffany & Co.',
	TH: 'TAG Heuer',
	TM: 'Techno Marine',
	TS: 'Tissot',
	TU: 'Tudor',
	UJ: 'Urban Juergensen & Sonneur',
	UN: 'Ulysse Nardin',
	UV: 'Universal',
	VC: 'Vacheron Constantin',
	VF: 'Van Cleef',
	ZN: 'Zenith',
}

export const Globals = {
	countries: [
		{name: 'United States', code: 'US'},
		{name: 'Canada', code: 'CA'},
		{name: 'United Kingdom', code: 'GB'},
		{name: 'Afghanistan', code: 'AF'},
		{name: 'Åland Islands', code: 'AX'},
		{name: 'Albania', code: 'AL'},
		{name: 'Algeria', code: 'DZ'},
		{name: 'American Samoa', code: 'AS'},
		{name: 'AndorrA', code: 'AD'},
		{name: 'Angola', code: 'AO'},
		{name: 'Anguilla', code: 'AI'},
		{name: 'Antarctica', code: 'AQ'},
		{name: 'Antigua and Barbuda', code: 'AG'},
		{name: 'Argentina', code: 'AR'},
		{name: 'Armenia', code: 'AM'},
		{name: 'Aruba', code: 'AW'},
		{name: 'Australia', code: 'AU'},
		{name: 'Austria', code: 'AT'},
		{name: 'Azerbaijan', code: 'AZ'},
		{name: 'Bahamas', code: 'BS'},
		{name: 'Bahrain', code: 'BH'},
		{name: 'Bangladesh', code: 'BD'},
		{name: 'Barbados', code: 'BB'},
		{name: 'Belarus', code: 'BY'},
		{name: 'Belgium', code: 'BE'},
		{name: 'Belize', code: 'BZ'},
		{name: 'Benin', code: 'BJ'},
		{name: 'Bermuda', code: 'BM'},
		{name: 'Bhutan', code: 'BT'},
		{name: 'Bolivia', code: 'BO'},
		{name: 'Bosnia and Herzegovina', code: 'BA'},
		{name: 'Botswana', code: 'BW'},
		{name: 'Bouvet Island', code: 'BV'},
		{name: 'Brazil', code: 'BR'},
		{name: 'British Indian Ocean Territory', code: 'IO'},
		{name: 'Brunei Darussalam', code: 'BN'},
		{name: 'Bulgaria', code: 'BG'},
		{name: 'Burkina Faso', code: 'BF'},
		{name: 'Burundi', code: 'BI'},
		{name: 'Cambodia', code: 'KH'},
		{name: 'Cameroon', code: 'CM'},
		{name: 'Cape Verde', code: 'CV'},
		{name: 'Cayman Islands', code: 'KY'},
		{name: 'Central African Republic', code: 'CF'},
		{name: 'Chad', code: 'TD'},
		{name: 'Chile', code: 'CL'},
		{name: 'China', code: 'CN'},
		{name: 'Christmas Island', code: 'CX'},
		{name: 'Cocos (Keeling) Islands', code: 'CC'},
		{name: 'Colombia', code: 'CO'},
		{name: 'Comoros', code: 'KM'},
		{name: 'Congo', code: 'CG'},
		{name: 'Congo, The Democratic Republic of the', code: 'CD'},
		{name: 'Cook Islands', code: 'CK'},
		{name: 'Costa Rica', code: 'CR'},
		{name: "Cote D'Ivoire", code: 'CI'},
		{name: 'Croatia', code: 'HR'},
		{name: 'Cuba', code: 'CU'},
		{name: 'Cyprus', code: 'CY'},
		{name: 'Czech Republic', code: 'CZ'},
		{name: 'Denmark', code: 'DK'},
		{name: 'Djibouti', code: 'DJ'},
		{name: 'Dominica', code: 'DM'},
		{name: 'Dominican Republic', code: 'DO'},
		{name: 'Ecuador', code: 'EC'},
		{name: 'Egypt', code: 'EG'},
		{name: 'El Salvador', code: 'SV'},
		{name: 'Equatorial Guinea', code: 'GQ'},
		{name: 'Eritrea', code: 'ER'},
		{name: 'Estonia', code: 'EE'},
		{name: 'Ethiopia', code: 'ET'},
		{name: 'Falkland Islands (Malvinas)', code: 'FK'},
		{name: 'Faroe Islands', code: 'FO'},
		{name: 'Fiji', code: 'FJ'},
		{name: 'Finland', code: 'FI'},
		{name: 'France', code: 'FR'},
		{name: 'French Guiana', code: 'GF'},
		{name: 'French Polynesia', code: 'PF'},
		{name: 'French Southern Territories', code: 'TF'},
		{name: 'Gabon', code: 'GA'},
		{name: 'Gambia', code: 'GM'},
		{name: 'Georgia', code: 'GE'},
		{name: 'Germany', code: 'DE'},
		{name: 'Ghana', code: 'GH'},
		{name: 'Gibraltar', code: 'GI'},
		{name: 'Greece', code: 'GR'},
		{name: 'Greenland', code: 'GL'},
		{name: 'Grenada', code: 'GD'},
		{name: 'Guadeloupe', code: 'GP'},
		{name: 'Guam', code: 'GU'},
		{name: 'Guatemala', code: 'GT'},
		{name: 'Guernsey', code: 'GG'},
		{name: 'Guinea', code: 'GN'},
		{name: 'Guinea-Bissau', code: 'GW'},
		{name: 'Guyana', code: 'GY'},
		{name: 'Haiti', code: 'HT'},
		{name: 'Heard Island and Mcdonald Islands', code: 'HM'},
		{name: 'Holy See (Vatican City State)', code: 'VA'},
		{name: 'Honduras', code: 'HN'},
		{name: 'Hong Kong', code: 'HK'},
		{name: 'Hungary', code: 'HU'},
		{name: 'Iceland', code: 'IS'},
		{name: 'India', code: 'IN'},
		{name: 'Indonesia', code: 'ID'},
		{name: 'Iran, Islamic Republic Of', code: 'IR'},
		{name: 'Iraq', code: 'IQ'},
		{name: 'Ireland', code: 'IE'},
		{name: 'Isle of Man', code: 'IM'},
		{name: 'Israel', code: 'IL'},
		{name: 'Italy', code: 'IT'},
		{name: 'Jamaica', code: 'JM'},
		{name: 'Japan', code: 'JP'},
		{name: 'Jersey', code: 'JE'},
		{name: 'Jordan', code: 'JO'},
		{name: 'Kazakhstan', code: 'KZ'},
		{name: 'Kenya', code: 'KE'},
		{name: 'Kiribati', code: 'KI'},
		{name: "Korea, Democratic People'S Republic of", code: 'KP'},
		{name: 'Korea, Republic of', code: 'KR'},
		{name: 'Kuwait', code: 'KW'},
		{name: 'Kyrgyzstan', code: 'KG'},
		{name: "Lao People'S Democratic Republic", code: 'LA'},
		{name: 'Latvia', code: 'LV'},
		{name: 'Lebanon', code: 'LB'},
		{name: 'Lesotho', code: 'LS'},
		{name: 'Liberia', code: 'LR'},
		{name: 'Libyan Arab Jamahiriya', code: 'LY'},
		{name: 'Liechtenstein', code: 'LI'},
		{name: 'Lithuania', code: 'LT'},
		{name: 'Luxembourg', code: 'LU'},
		{name: 'Macao', code: 'MO'},
		{name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
		{name: 'Madagascar', code: 'MG'},
		{name: 'Malawi', code: 'MW'},
		{name: 'Malaysia', code: 'MY'},
		{name: 'Maldives', code: 'MV'},
		{name: 'Mali', code: 'ML'},
		{name: 'Malta', code: 'MT'},
		{name: 'Marshall Islands', code: 'MH'},
		{name: 'Martinique', code: 'MQ'},
		{name: 'Mauritania', code: 'MR'},
		{name: 'Mauritius', code: 'MU'},
		{name: 'Mayotte', code: 'YT'},
		{name: 'Mexico', code: 'MX'},
		{name: 'Micronesia, Federated States of', code: 'FM'},
		{name: 'Moldova, Republic of', code: 'MD'},
		{name: 'Monaco', code: 'MC'},
		{name: 'Mongolia', code: 'MN'},
		{name: 'Montserrat', code: 'MS'},
		{name: 'Morocco', code: 'MA'},
		{name: 'Mozambique', code: 'MZ'},
		{name: 'Myanmar', code: 'MM'},
		{name: 'Namibia', code: 'NA'},
		{name: 'Nauru', code: 'NR'},
		{name: 'Nepal', code: 'NP'},
		{name: 'Netherlands', code: 'NL'},
		{name: 'Netherlands Antilles', code: 'AN'},
		{name: 'New Caledonia', code: 'NC'},
		{name: 'New Zealand', code: 'NZ'},
		{name: 'Nicaragua', code: 'NI'},
		{name: 'Niger', code: 'NE'},
		{name: 'Nigeria', code: 'NG'},
		{name: 'Niue', code: 'NU'},
		{name: 'Norfolk Island', code: 'NF'},
		{name: 'Northern Mariana Islands', code: 'MP'},
		{name: 'Norway', code: 'NO'},
		{name: 'Oman', code: 'OM'},
		{name: 'Pakistan', code: 'PK'},
		{name: 'Palau', code: 'PW'},
		{name: 'Palestinian Territory, Occupied', code: 'PS'},
		{name: 'Panama', code: 'PA'},
		{name: 'Papua New Guinea', code: 'PG'},
		{name: 'Paraguay', code: 'PY'},
		{name: 'Peru', code: 'PE'},
		{name: 'Philippines', code: 'PH'},
		{name: 'Pitcairn', code: 'PN'},
		{name: 'Poland', code: 'PL'},
		{name: 'Portugal', code: 'PT'},
		{name: 'Puerto Rico', code: 'PR'},
		{name: 'Qatar', code: 'QA'},
		{name: 'Reunion', code: 'RE'},
		{name: 'Romania', code: 'RO'},
		{name: 'Russian Federation', code: 'RU'},
		{name: 'RWANDA', code: 'RW'},
		{name: 'Saint Helena', code: 'SH'},
		{name: 'Saint Kitts and Nevis', code: 'KN'},
		{name: 'Saint Lucia', code: 'LC'},
		{name: 'Saint Pierre and Miquelon', code: 'PM'},
		{name: 'Saint Vincent and the Grenadines', code: 'VC'},
		{name: 'Samoa', code: 'WS'},
		{name: 'San Marino', code: 'SM'},
		{name: 'Sao Tome and Principe', code: 'ST'},
		{name: 'Saudi Arabia', code: 'SA'},
		{name: 'Senegal', code: 'SN'},
		{name: 'Serbia and Montenegro', code: 'CS'},
		{name: 'Seychelles', code: 'SC'},
		{name: 'Sierra Leone', code: 'SL'},
		{name: 'Singapore', code: 'SG'},
		{name: 'Slovakia', code: 'SK'},
		{name: 'Slovenia', code: 'SI'},
		{name: 'Solomon Islands', code: 'SB'},
		{name: 'Somalia', code: 'SO'},
		{name: 'South Africa', code: 'ZA'},
		{name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
		{name: 'Spain', code: 'ES'},
		{name: 'Sri Lanka', code: 'LK'},
		{name: 'Sudan', code: 'SD'},
		{name: 'Suriname', code: 'SR'},
		{name: 'Svalbard and Jan Mayen', code: 'SJ'},
		{name: 'Swaziland', code: 'SZ'},
		{name: 'Sweden', code: 'SE'},
		{name: 'Switzerland', code: 'CH'},
		{name: 'Syrian Arab Republic', code: 'SY'},
		{name: 'Taiwan, Province of China', code: 'TW'},
		{name: 'Tajikistan', code: 'TJ'},
		{name: 'Tanzania, United Republic of', code: 'TZ'},
		{name: 'Thailand', code: 'TH'},
		{name: 'Timor-Leste', code: 'TL'},
		{name: 'Togo', code: 'TG'},
		{name: 'Tokelau', code: 'TK'},
		{name: 'Tonga', code: 'TO'},
		{name: 'Trinidad and Tobago', code: 'TT'},
		{name: 'Tunisia', code: 'TN'},
		{name: 'Turkey', code: 'TR'},
		{name: 'Turkmenistan', code: 'TM'},
		{name: 'Turks and Caicos Islands', code: 'TC'},
		{name: 'Tuvalu', code: 'TV'},
		{name: 'Uganda', code: 'UG'},
		{name: 'Ukraine', code: 'UA'},
		{name: 'United Arab Emirates', code: 'AE'},
		{name: 'United States Minor Outlying Islands', code: 'UM'},
		{name: 'Uruguay', code: 'UY'},
		{name: 'Uzbekistan', code: 'UZ'},
		{name: 'Vanuatu', code: 'VU'},
		{name: 'Venezuela', code: 'VE'},
		{name: 'Viet Nam', code: 'VN'},
		{name: 'Virgin Islands, British', code: 'VG'},
		{name: 'Virgin Islands, U.S.', code: 'VI'},
		{name: 'Wallis and Futuna', code: 'WF'},
		{name: 'Western Sahara', code: 'EH'},
		{name: 'Yemen', code: 'YE'},
		{name: 'Zambia', code: 'ZM'},
		{name: 'Zimbabwe', code: 'ZW'},
	],
	states: {
		AL: 'Alabama',
		AK: 'Alaska',
		AS: 'American Samoa',
		AZ: 'Arizona',
		AR: 'Arkansas',
		CA: 'California',
		CO: 'Colorado',
		CT: 'Connecticut',
		DE: 'Delaware',
		DC: 'District Of Columbia',
		FM: 'Federated States Of Micronesia',
		FL: 'Florida',
		GA: 'Georgia',
		GU: 'Guam',
		HI: 'Hawaii',
		ID: 'Idaho',
		IL: 'Illinois',
		IN: 'Indiana',
		IA: 'Iowa',
		KS: 'Kansas',
		KY: 'Kentucky',
		LA: 'Louisiana',
		ME: 'Maine',
		MH: 'Marshall Islands',
		MD: 'Maryland',
		MA: 'Massachusetts',
		MI: 'Michigan',
		MN: 'Minnesota',
		MS: 'Mississippi',
		MO: 'Missouri',
		MT: 'Montana',
		NE: 'Nebraska',
		NV: 'Nevada',
		NH: 'New Hampshire',
		NJ: 'New Jersey',
		NM: 'New Mexico',
		NY: 'New York',
		NC: 'North Carolina',
		ND: 'North Dakota',
		MP: 'Northern Mariana Islands',
		OH: 'Ohio',
		OK: 'Oklahoma',
		OR: 'Oregon',
		PW: 'Palau',
		PA: 'Pennsylvania',
		PR: 'Puerto Rico',
		RI: 'Rhode Island',
		SC: 'South Carolina',
		SD: 'South Dakota',
		TN: 'Tennessee',
		TX: 'Texas',
		UT: 'Utah',
		VT: 'Vermont',
		VI: 'Virgin Islands',
		VA: 'Virginia',
		WA: 'Washington',
		WV: 'West Virginia',
		WI: 'Wisconsin',
		WY: 'Wyoming',
	},
	watchBrands: watchBrands,
	inventoryViewStatus: inventoryViewStatus,
	saleTerms: saleTerms,
	keyboardButton: keyboardButton,
	inventorySaleStatus: inventorySaleStatus,
	inventoryMemoStatus: inventoryMemoStatus,
	inventoryStatuses: inventoryStatuses,
	warrantyCardsList: warrantyCardsList,
	deviceId: fingerprint,
	convertToMoney: convertToMoney,
	moneyFormatter: moneyFormatter,
	TokenKey: TokenKey,
	New_TokenKey: New_TokenKey,
	New_TokenKey_Expiry: New_TokenKey_Expiry,
	REFRESH_TOKEN: REFRESH_TOKEN,
	REFRESH_TOKEN_EXPIRY: REFRESH_TOKEN_EXPIRY,
	USERPROFILE: USERPROFILE,
	USERSELECTEDSPACE: USERSELECTEDSPACE,
	ENDPOINTVERSION: ENDPOINTVERSION,
	SELECTEDSPACEID: SELECTEDSPACEID,
	PUBLIC_KEY_STRIPE: PUBLIC_KEY_STRIPE,
	Sensitive_Data_Column: Sensitive_Data_Column,
	Token: Cache.get(TokenKey),
	formatCurrency: formatCurrency,
	formatPhoneNumber: formatPhoneNumber,
	Cache: Cache,
	wsurl: wsurl,
	NEW_API_URL: NEW_API_URL,
	ssurl: ssurl,
	msurl: `${wsurl}/media/`,
	signupurl: `${ssurl}/register/`,
	signinurl: `${ssurl}/login/`,
	actionCableURL: ACTION_CABLE_URL,
	validateEmail: validateEmail,
	_Axios: Axios,
	baseUri: `${NEW_API_URL}/${ENDPOINTVERSION}/spaces/${SELECTEDSPACEID}`,
	currentSpace: JSON.parse(Cache.get(USERSELECTEDSPACE)),
	isOwner: JSON.parse(Cache.get(USERSELECTEDSPACE))?.role === 'owner',
	currentUserId: JSON.parse(Cache.get(USERPROFILE))?.id,
	watchSearchParams: 'sku_or_reference_number_or_display_status_or_brand_or_series_or_serial_number_or_dial_or_bezel_or_bracelet_or_addition_details_or_vendor_or_profit_str_or_space_name_i_cont',
	marketplaceSearchParams: 'sku_or_reference_number_or_display_status_or_brand_or_series_or_serial_number_or_dial_or_bezel_or_bracelet_or_addition_details_or_vendor_or_space_name_i_cont',
	getBrandName: brand => {
		return !isEmpty(watchBrands[brand]) ? watchBrands[brand] : brand
	},
	shareDomain: process.env.SHARE_DOMAIN,
	authKey: process.env.GOOGLE_PLACES_TOKEN,
	getFormattedTitleShort: (data, withSerial = false) => {
		let title = []

		const brand = !isEmpty(watchBrands[data?.brand]) ? watchBrands[data?.brand] : data?.brand

		if (brand) title = [...title, brand]
		if (data?.series) title = [...title, data?.series]
		if (data?.reference_number) title = [...title, `${data?.reference_number}`]
		return title.join(' ')
	},
	getFormattedTitle: (data, withSerial = false) => {
		let title = []

		const brand = !isEmpty(watchBrands[data?.brand]) ? watchBrands[data?.brand] : data?.brand

		if (brand) title = [...title, brand]
		if (data?.series) title = [...title, data?.series]
		if (data?.dial) title = [...title, `${data?.dial} Dial`]
		if (data?.bracelet) title = [...title, `on ${data?.bracelet} Bracelet`]
		if (data?.reference_number) title = [...title, `Ref. ${data?.reference_number}`]
		if (data?.serial_number && withSerial) title = [...title, `Serial No ${data?.serial_number}`]

		const warranty = new Date(data?.warranty).getFullYear()
		if (data?.warranty_papers && warranty && !isNaN(warranty)) {
			title = [...title, `with ${data?.WarrantyPaper}`]
			title = [...title, warranty]
		} else if (data?.warranty_papers && !['no', 'No'].includes(data?.warranty_papers)) {
			title = [...title, `with ${data?.warranty_papers}`]
		}

		return title.join(' ')
	},

	Axios: () => {
		let axios = Axios.create({
			timeout: 60000,
			// withCredentials: true,
		})
		axios.defaults.headers.deviceId = Globals.deviceId
		axios.defaults.headers.token = Cache.get(TokenKey)
		return axios
	},
	New_Axios: ({ logOutWhenUnauthorized } = { logOutWhenUnauthorized: true }) => {
		let axios = Axios.create({
			timeout: 60000,
			// withCredentials: true,
		})
		axios.defaults.headers.Authorization = Cache.get(New_TokenKey)
		//axios.defaults.headers['Content-Type'] = 'multipart/form-data'

		axios.interceptors.request.use(
			async config => {
				config.headers.Timezone = moment().utcOffset()/60.0;

				if (config.skipRefreshTokenInterceptor) {
					return config;
				}
				if (!isEmpty(Globals.Cache.get(Globals.USERPROFILE))) {
					const refreshTokenExpiry = Globals.Cache.get(Globals.REFRESH_TOKEN_EXPIRY);
					const accessTokenExpiry = Globals.Cache.get(Globals.New_TokenKey_Expiry);
					if (refreshTokenExpiry && accessTokenExpiry) {
						const isRefreshTokenExpired = ((refreshTokenExpiry - new Date()) <= 0);
						const isAccessTokenExpired = ((accessTokenExpiry - new Date()) <= 0);

						if (isRefreshTokenExpired) {
							logOut();
						} else if (isAccessTokenExpired) {
							const accessToken = await refreshToken(axios);
							config.headers.Authorization = accessToken;
						} else {
							return config;
						}
					}
				}

				return config;
			},
			error => {
			  return Promise.reject(error);
			}
		);

		axios.interceptors.response.use((response) => {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response;
		  },(error) => {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			if (logOutWhenUnauthorized && error.response && error.response.status === 401 && error.response.data.type !== 'has_not_accepted_tos') {
				store.dispatch(logOut())
				return
			}

			return Promise.reject(error);
		  });

		return axios
	},
	getAccessToken: () => {
		return Cache.get(New_TokenKey)
	},
	getProfile: () => {
		const params = {}
		if (!isNil(Globals.SELECTEDSPACEID)) {
			params.space_id = Globals.SELECTEDSPACEID
		}

		return new Promise((resolve, reject) => {
			Globals.New_Axios({logOutWhenUnauthorized: false})
				.get(`${Globals.NEW_API_URL}/v1/me`, {params})
				.then(response => {
					return resolve(response.data)
				})
				.catch(error => {
					return reject(error)
				})
		})
	},
	loginChangeHandler: cb => {
		window.addEventListener('storage', e => {
			if (e.key === TokenKey) {
				if (e.newValue === null || e.newValue.length === 0) {
					window.location.reload()
					cb()
				}
			}
		})
	},
	adminOrOwner: (profile) => {
		return profile?.role === 'admin' || profile?.role === 'owner'
	},
	adminOrOwnerOrEditor: (profile) => {
		return (profile?.role === "owner" || profile?.role === "admin" ||  profile?.role === "editor")
	},
	isAllScopeOfDeliveryShown: (visibleFields) => {
		return intersection([
			'condition',
			'warranty_papers',
			'warranty',
			'box',
			'link_count',
			'addition_details',
		], visibleFields).length === 0;
	},
	isAllPriceInfoShown: (visibleFields) => {
		return intersection(['msrp_price', 'wholesale_price', 'cost', 'date_purchased', 'vendor'], visibleFields).length === 0;
	},
}
