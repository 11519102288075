import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {connect} from 'react-redux'

import {Globals} from '../Context'
import {setCurrentSpace} from '../app/appActions'

const PaymentSuccess = ({setCurrentSpace}) => {
	const navigate = useNavigate()
	const location = useLocation()
	const getSpaceDetail = () => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}`)
			.then(response => {
				setCurrentSpace(response.data)
			})
	}

	const handleUpdateStatusPayment = async formType => {
		try {
			if (formType === 'register') {
				navigate('/thank-you')
			} else {
				getSpaceDetail()
				navigate('/inventory', {
					state: {
						successPayment: true,
						message: 'Payment was successful',
					},
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const redirectStatus = searchParams.get('redirect_status')
		const formType = searchParams.get('form_type')
		if (redirectStatus === 'succeeded') {
			handleUpdateStatusPayment(formType)
		}
	}, [])

	return <></>
}

const mapStateToProps = (state, props) => {
	return {
		currentSpace: state.currentSpace,
	}
}
const mapActionsToProps = {
	setCurrentSpace,
}

export default connect(mapStateToProps, mapActionsToProps)(PaymentSuccess)
