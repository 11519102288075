import React, {Suspense, useEffect, useMemo} from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {Globals} from './Context'
import {Provider, useSelector} from 'react-redux'
import {store} from './app/store'
import {logOut} from './app/appActions'
import momentTimeZone from 'moment-timezone'

import reportWebVitals from './reportWebVitals'
import './index.css'

import Pickspace from './Components/Pickspace/Pickspace'
import DryConfirmation from './Components/DryConfirmation/DryConfirmation'
import SettingsVariable from './Context/SettingsVariable'
import AddInventory from './Components/Inventory/AddInventory'

import Bugsnag from '@bugsnag/js'

import BugsnagPluginReact from '@bugsnag/plugin-react'
import WebStore from './Pages/WebStore'
import {RequireHttps} from './utils/RequireHttps'
import {fetchProfile, getSpaceDetail} from './utils/UserSessionHelper'
import AppcuesService from './services/AppcuesService'
import {buildUserProperties} from './utils/AppcuesProperties'
import RouterWrapper from './RouterWrapper'
import Watch from './Components/Watch'
import NotFound from './Components/NotFound'
import PaymentSuccess from './Components/PaymentSuccess'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import Login from './Pages/Login'

const Register = React.lazy(() => import('./Pages/Register'))
const Main = React.lazy(() => import('./Pages/Main'))
const Inventory = React.lazy(() => import('./Pages/Inventory'))
const SoldInventory = React.lazy(() => import('./Pages/SoldInventory'))
const InventoryShare = React.lazy(() => import('./Pages/InventoryShare'))
const InventoryShareGallery = React.lazy(() => import('./Pages/InventoryShareGallery'))
const Marketplace = React.lazy(() => import('./Pages/Marketplace'))
const Messages = React.lazy(() => import('./Pages/Messages'))
const Memo = React.lazy(() => import('./Pages/Memo'))
const Sales = React.lazy(() => import('./Pages/Sales'))
const Customers = React.lazy(() => import('./Pages/Customers'))
const Account = React.lazy(() => import('./Pages/Account'))
const TermsOfUse = React.lazy(() => import('./Pages/TermsOfUse'))
const PrivacyPolicy = React.lazy(() => import('./Pages/PrivacyPolicy'))
const PasswordSetting = React.lazy(() => import('./Pages/PasswordSetting'))
const PasswordAuthentication = React.lazy(() => import('./Pages/PasswordAuthentication'))
const WebStoreExpired = React.lazy(() => import('./Pages/WebStoreExpired'))
const InventoryItemShare = React.lazy(() => import('./Pages/InventoryItemShare'))
const ThankYouForSigningUp = React.lazy(() => import('./Pages/ThankYouForSigningUp'))

const LoaderComponent = () => {
	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
			<button
				type="button"
				className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
				disabled
			>
				<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
					{' '}
					<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
					<path
						className="opacity-75"
						fill="currentColor"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					/>
				</svg>
				<span>Loading... </span>
			</button>
		</div>
	)
}

const SuspenseWrapper = ({children}) => {
	return (
		<Suspense fallback={<LoaderComponent />}>
			{children}
		</Suspense>
	)
}

let customErrorBoundaryPrevStoreData = {}
const CustomErrorBoundary = ({error, info, children}) => {
	const storeData = useSelector(state => ({
			profile: state.profile,
			space: state.currentSpace
		}
	))

	if (
		storeData.profile &&
		storeData.space &&
		customErrorBoundaryPrevStoreData.profile !== storeData.profile &&
		customErrorBoundaryPrevStoreData.space !== storeData.space
	  ) {
		const {profile: {id, full_name}, space} = storeData
		const sessionMetadata = {
			space_name: space.name,
			space_id: space.id,
			user_name: full_name,
			user_id: id
		}
		Bugsnag.start({
			apiKey: process.env.BUGSNAG_API_KEY,
			plugins: [new BugsnagPluginReact()],
			metadata: sessionMetadata,
			onError: (event) => {
				// Check if the error is a non-Error object or unhandled rejection
				if (event.errors[0].errorMessage.includes('unhandledrejection handler received a non-error')) {
					return false
				}
				event.addMetadata('meta', sessionMetadata)
				// Allow all other errors to be reported
				return true
			}
		})
	}

	if (customErrorBoundaryPrevStoreData !== storeData) {
		customErrorBoundaryPrevStoreData = storeData
	}

	const Component = useMemo(() => {
		return Bugsnag.isStarted() ? Bugsnag.getPlugin('react').createErrorBoundary(React) : React.Fragment
	}, [Bugsnag.isStarted()])
	return (
		<Component>
			{children}
		</Component>
	)
}
const init = async () => {
	if (window.location.host.startsWith('app.') &&
		(window.location.pathname.startsWith('/store/') || window.location.pathname.startsWith('/inventory/share/'))) {
		window.location.host = window.location.host.replace('app.', 'share.')
	}

	if (!window.location.pathname.startsWith('/store/')) {
		await fetchProfile()

		if (!!Globals.SELECTEDSPACEID) {
			await getSpaceDetail()
		}

		if (!!Globals.currentUserId) {
			const user = Globals.Cache.get(Globals.USERPROFILE)
			AppcuesService.initialize(Globals.currentUserId, buildUserProperties(JSON.parse(user)))
		}

		//Whenever the user login status change this will trigger.
		await Globals.loginChangeHandler(() => {
			store.dispatch(logOut())
		})
	}

	window.addEventListener('storage', (event) => {
		if (event.key === 'logoutEvent') {
			store.dispatch(logOut())
		} else if (event.key === 'loginSpace') {
			window.location = '/'
		} else if (event.key === 'logoutSpace') {
			Globals.Cache.deleteCookie(Globals.USERSELECTEDSPACE)
			Globals.Cache.deleteStorage(Globals.USERSELECTEDSPACE)
			Globals.Cache.deleteCookie(Globals.Sensitive_Data_Column)
			Globals.Cache.deleteStorage(Globals.Sensitive_Data_Column)
			window.location = '/'
		}
	})

	const root = ReactDOM.createRoot(document.getElementById('root'))

	if (!isEmpty(Globals.Cache.get(Globals.USERPROFILE)) && isNull(Globals.Cache.get(Globals.USERSELECTEDSPACE))) {
		return root.render(
			<>
				{process.env.NODE_ENV === 'production' && <RequireHttps />}
				<Provider store={store}>
					<CustomErrorBoundary>
						<SettingsVariable>
							<BrowserRouter>
								<Routes>
									<Route exact path="*" element={<SuspenseWrapper><Pickspace /></SuspenseWrapper>} />
									<Route exact path="/pickspace"
										   element={<SuspenseWrapper><Pickspace /></SuspenseWrapper>} />
									<Route exact path="/register/continue/:token"
										   element={<SuspenseWrapper><Register continuous /></SuspenseWrapper>} />
									<Route exact path="/register/internal_invite/:token"
										   element={<SuspenseWrapper><Register internalInvite /></SuspenseWrapper>} />
									<Route exact path="/thank-you"
										   element={<SuspenseWrapper><ThankYouForSigningUp /></SuspenseWrapper>} />
									<Route exact path="/dry/confirmation/:token"
										   element={<SuspenseWrapper><DryConfirmation /></SuspenseWrapper>} />
									<Route exact path="/inventory/share/:id"
										   element={<SuspenseWrapper><InventoryShare /></SuspenseWrapper>} />
									<Route exact path="/inventory/share/gallery/:id"
										   element={<SuspenseWrapper><InventoryShareGallery /></SuspenseWrapper>} />
									<Route exact path="/inventory/share/item/:id"
										   element={<SuspenseWrapper><InventoryItemShare /></SuspenseWrapper>} />
									<Route exact path="/store/:uid/:slug"
										   element={<SuspenseWrapper><WebStore /></SuspenseWrapper>} />
									<Route exact path="/store/:slug"
										   element={<SuspenseWrapper><WebStoreExpired /></SuspenseWrapper>} />
									<Route exact path="/watches/:id"
										   element={<SuspenseWrapper><Watch /></SuspenseWrapper>} />
									<Route exact path="/not-found"
										   element={<SuspenseWrapper><NotFound /></SuspenseWrapper>} />
									<Route exact path="/payment-success"
									       element={<SuspenseWrapper><PaymentSuccess /></SuspenseWrapper>} />
								</Routes>
							</BrowserRouter>
						</SettingsVariable>
					</CustomErrorBoundary>
				</Provider>
			</>
		)
	}

	if (isEmpty(Globals.Cache.get(Globals.USERPROFILE))) {
		return root.render(
			<>
				{process.env.NODE_ENV === 'production' && <RequireHttps />}
				<Provider store={store}>
					<CustomErrorBoundary>
						<SettingsVariable>
							<BrowserRouter>
								<Routes>
									<Route exact path="*" element={<Login />} />
									<Route exact path="/" element={<Login />} />
									<Route exact path="/login" element={<Login />} />
									<Route exact path="/thank-you"
										   element={<SuspenseWrapper><ThankYouForSigningUp /></SuspenseWrapper>} />
									<Route exact path="/password-setting"
										   element={<SuspenseWrapper><PasswordSetting /></SuspenseWrapper>} />
									<Route exact path="/reset/password/:token"
										   element={<SuspenseWrapper><PasswordAuthentication /></SuspenseWrapper>} />
									<Route exact path="/dry/confirmation/:token"
										   element={<SuspenseWrapper><DryConfirmation /></SuspenseWrapper>} />
									<Route exact path="/terms-of-use"
										   element={<SuspenseWrapper><TermsOfUse /></SuspenseWrapper>} />
									<Route exact path="/privacy-policy"
										   element={<SuspenseWrapper><PrivacyPolicy /></SuspenseWrapper>} />
									<Route exact path="/login/continue/:token"
										   element={<SuspenseWrapper><Login continuous /></SuspenseWrapper>} />
									<Route exact path="/register"
										   element={<SuspenseWrapper><Register /></SuspenseWrapper>} />
									<Route exact path="/register/continue/:token"
										   element={<SuspenseWrapper><Register continuous /></SuspenseWrapper>} />
									<Route exact path="/register/internal_invite/:token"
										   element={<SuspenseWrapper><Register internalInvite /></SuspenseWrapper>} />
									<Route exact path="/inventory/share/:id"
										   element={<SuspenseWrapper><InventoryShare /></SuspenseWrapper>} />
									<Route exact path="/inventory/share/gallery/:id"
										   element={<SuspenseWrapper><InventoryShareGallery /></SuspenseWrapper>} />
									<Route exact path="/inventory/share/item/:id"
										   element={<SuspenseWrapper><InventoryItemShare /></SuspenseWrapper>} />
									<Route exact path="/store/:uid/:slug"
										   element={<SuspenseWrapper><WebStore /></SuspenseWrapper>} />
									<Route exact path="/store/:slug"
										   element={<SuspenseWrapper><WebStoreExpired /></SuspenseWrapper>} />
									<Route exact path="/watches/:id"
										   element={<SuspenseWrapper><Watch /></SuspenseWrapper>} />
									<Route exact path="/not-found"
										   element={<SuspenseWrapper><NotFound /></SuspenseWrapper>} />
								</Routes>
							</BrowserRouter>
						</SettingsVariable>
					</CustomErrorBoundary>
				</Provider>
			</>
		)
	}

	return root.render(
		<>
			{process.env.NODE_ENV === 'production' && <RequireHttps />}
			<Provider store={store}>
				<CustomErrorBoundary>
					<SettingsVariable>
						<BrowserRouter>
							<RouterWrapper>
								<Routes>
									<Route exact path="/" element={<Login />} />
									<Route exact path="/login" element={<Login />} />
									<Route exact path="/thank-you"
										   element={<SuspenseWrapper><ThankYouForSigningUp /></SuspenseWrapper>} />
									<Route exact path="/dry/confirmation/:token"
										   element={<SuspenseWrapper><DryConfirmation /></SuspenseWrapper>} />
									<Route exact path="/login/continue/:token"
										   element={<SuspenseWrapper><Login continuous /></SuspenseWrapper>} />
									<Route exact path="/register"
										   element={<SuspenseWrapper><Register /></SuspenseWrapper>} />
									<Route exact path="/register/continue/:token"
										   element={<SuspenseWrapper><Register continuous /></SuspenseWrapper>} />
									<Route exact path="/register/internal_invite/:token"
										   element={<SuspenseWrapper><Register internalInvite /></SuspenseWrapper>} />
									<Route exact path="/dashboard"
										   element={<SuspenseWrapper><Main /></SuspenseWrapper>} />
									<Route exact path="/inventory"
										   element={<SuspenseWrapper><Inventory /></SuspenseWrapper>} />
									<Route exact path="/inventory-sold"
										   element={<SuspenseWrapper><SoldInventory /></SuspenseWrapper>} />
									<Route exact path="/inventory/add"
										   element={<SuspenseWrapper><AddInventory /></SuspenseWrapper>} />
									<Route exact path="/marketplace"
										   element={<SuspenseWrapper><Marketplace /></SuspenseWrapper>} />
									<Route exact path="/messages"
										   element={<SuspenseWrapper><Messages /></SuspenseWrapper>} />
									<Route exact path="/messages/:conversation_id"
										   element={<SuspenseWrapper><Messages /></SuspenseWrapper>} />
									<Route exact path="/messages/:type/:product"
										   element={<SuspenseWrapper><Messages /></SuspenseWrapper>} />
									<Route exact path="/messages/:type/:product/:price"
										   element={<SuspenseWrapper><Messages /></SuspenseWrapper>} />
									<Route exact path="/memo" element={<SuspenseWrapper><Memo /></SuspenseWrapper>} />
									<Route exact path="/sales" element={<SuspenseWrapper><Sales /></SuspenseWrapper>} />
									<Route exact path="/customers"
										   element={<SuspenseWrapper><Customers /></SuspenseWrapper>} />
									<Route exact path="/account"
										   element={<SuspenseWrapper><Account /></SuspenseWrapper>} />
									<Route exact path="/inventory/share/:id"
										   element={<SuspenseWrapper><InventoryShare /></SuspenseWrapper>} />
									<Route exact path="/inventory/share/gallery/:id"
										   element={<SuspenseWrapper><InventoryShareGallery /></SuspenseWrapper>} />
									<Route exact path="/inventory/share/item/:id"
										   element={<SuspenseWrapper><InventoryItemShare /></SuspenseWrapper>} />
									<Route exact path="/store/:uid/:slug"
										   element={<SuspenseWrapper><WebStore /></SuspenseWrapper>} />
									<Route exact path="/store/:slug"
										   element={<SuspenseWrapper><WebStoreExpired /></SuspenseWrapper>} />
									<Route exact path="/watches/:id"
										   element={<SuspenseWrapper><Watch /></SuspenseWrapper>} />
									<Route exact path="/not-found"
										   element={<SuspenseWrapper><NotFound /></SuspenseWrapper>} />
									<Route exact path="/payment-success"
										   element={<SuspenseWrapper><PaymentSuccess /></SuspenseWrapper>} />
								</Routes>
							</RouterWrapper>
						</BrowserRouter>
					</SettingsVariable>
				</CustomErrorBoundary>
			</Provider>
		</>
	)
}

init()
reportWebVitals()
