import { useEffect, useState } from 'react';
import Classnames from 'classnames'
import { Tooltip } from '@mui/material';
import isFunction from 'lodash/isFunction'

const TermsOfUse = ({hideTerms, formData, updateFormData, showButtons, handleSubmit, newUser}) => {
	const [disabledButton, setDisabledButton] = useState(true);

	useEffect(() => {
		const scrollContainer = document.getElementById('terms-scroll-container');

		const handleScroll = () => {
		  // Add a small tolerance to account for floating point precision issues
		  const tolerance = 5;

		  if (scrollContainer.scrollHeight - scrollContainer.scrollTop <= scrollContainer.clientHeight + tolerance) {
			if (!disabledButton) return;
			setDisabledButton(false);
		  }
		};

		scrollContainer.addEventListener('scroll', handleScroll);

		// Cleanup the event listener when the component unmounts
		return () => {
		  scrollContainer.removeEventListener('scroll', handleScroll);
		};
	  }, []);

	return (
		<>
			<div className="fixed top-0 right-0 left-0 bottom-0 w-screen h-screen bg-black/50 flex items-center justify-center z-50 py-5">
				<div className="drop-shadow-xl rounded-xl w-full overflow-hidden flex flex-col justify-center" style={{maxWidth: '1200px'}}>
					<div className="w-full borderCardChrono relative px-8 py-4 flex items-center">
						<span className="text-white font-bold text-lg">Terms & Conditions</span>
						<button className="absolute right-0 mr-8 cursor-pointer" onClick={hideTerms}>
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z"
									fill="white"
								/>
							</svg>
						</button>
					</div>

					<div className="Container md:w-50 w-50 ">
						<div className="row md:w-100% flex flex-col items-center justify-center md:pb-0 pb-16 md:pt-0 pt-10 m-auto  text-white" style={{backgroundColor: '#2c79e0'}}>
							<div id="terms-scroll-container" className="col-md-12 p-5 popup-style !mb-0">
								<div className="">
									<img className="m-auto pb-5" src="https://elefta-site-images.s3.us-west-1.amazonaws.com/logo.png" alt="" width="350" height="345" />
								</div>

								<h1 className="font-bold ">END USER LICENSE AGREEMENT</h1>
								<h6 className="py-3 ">BY CLICKING TO ACCEPT YOU INDICATE YOUR COMPLETE AGREEMENT TO THE TERMS BELOW</h6>
								<p className="text-justify">
									1. LICENSOR AND LICENSEE. ELEFTA INC. (“LICENSOR”) PROVIDES THIS INTERNET-ACCESSIBLE SOFTWARE AS A SERVICE THAT PROVIDES USE OF LICENSOR’S SOFTWARE RELATED TO
									INVENTORY MANAGEMENT OF WATCHES AND MADE AVAILABLE TO LICENSEE ON A TERM-USE BASIS (“SaaS” or “Software”). THE SaaS AND LICENSE GRANT ARE FOR COMMERCIAL USE ONLY BY
									A LEGALLY ESTABLISHED BUSINESS, AND NOT FOR PERSONAL USE (“LICENSEE”). IF YOU ARE NOT A LEGALLY ESTABLISHED BUSINESS USING SaaS SOLELY FOR COMMERCIAL USE, THEN
									LICENSOR MAY IMMEDIATELY SUSPEND OR TERMINATE THE SaaS LICENSE, ACCESS, AND SaaS USE AT ANY TIME, WITHOUT NOTICE. LICENSOR PROVIDES THE SaaS TO LICENSEE UNDER THIS
									END USER LICENSE AGREEMENT (“AGREEMENT”), AND SUBJECT TO LICENSEE MAKING FULL PAYMENT FOR THIS LICENSE, AND COMPLETELY AND ACCURATELY EXECUTING A SEPARATE ONLINE
									ORDER FORM (“Online Order Form”) PRIOR TO ANY ACCESS, OR USE OF THE SaaS . THIS AGREEMENT GOVERNS LICENSEE’S ACCESS, AND USE OF THE VERSION OF THE LICENSED SOFTWARE
									ON THIS WEBSITE, APPLICATION PROGRAM OR MOBILE SITE. LICENSEE’S ACCESS, OR USE OF THE SaaS CONSTITUTES ACCEPTANCE OF THIS AGREEMENT. IF YOU, LICENSEE, DO NOT AGREE
									TO ALL THE TERMS AND CONDITIONS CONTAINED IN THIS AGREEMENT, PLEASE DO NOT ACCESS, OR USE THE SaaS. THE TERMS AND CONDITIONS OF THIS AGREEMENT MAY BE DIFFERENT FROM
									THE AGREEMENT(S) THAT ACCOMPANIED EARLIER RELEASES OF THE LICENSED SOFTWARE. PLEASE READ THIS AGREEMENT CAREFULLY BEFORE PROCEEDING, AS IT MAY CONTAIN ADDITIONAL
									RESTRICTIONS ON YOUR USE OF THE SaaS OR DIFFERENT TERMS AND CONDITIONS. THIS AGREEMENT SUPERSEDES AND CONTROLS OVER ANY OTHER TERMS AND CONDITIONS PROVIDED TO
									LICENSEE REGARDING LICENSEE’S USE OF THE SaaS, WHETHER WRITTEN OR ORAL, OR AS PART OF A SIGNED AGREEMENT.. LICENSOR RESERVES THE RIGHT TO UPDATE, AMEND, AND/OR
									MODIFY THIS AGREEMENT, AT ANY TIME, WITHOUT NOTICE TO THE LICENSEE. LICENSOR MAY MAKE CHANGES TO THE SaaS, AT ANY TIME, WITHOUT NOTICE TO THE LICENSEE. LICENSEE
									AGREES TO COMPLETELY AND ACCURATELY FILL OUT THE SEPARATE ONLINE ORDER FORM. LICENSOR AND LICENSEE ARE EACH A “PARTY” AND COLLECTIVELY THE “PARTIES.”
								</p>
								<p>Capitalized terms in this Agreement are defined in the Agreement or as follows: </p>
								<p>
									<strong>“Licensed Software” </strong> means the user documentation that Licensor makes available for the SaaS in electronic form or paper form.{' '}
								</p>
								<p>
									<strong>“Licensed Software” </strong> means the Licensor’s SaaS provided to Licensee under this Agreement. This Agreement shall govern the use of the Licensed
									Software Any updated Software may be specifically governed by a different end user license agreement, with an additional fee.{' '}
								</p>
								<p>
									<strong>“Product Order” </strong> means an agreement in form in Exhibit A between Licensor and Licensee. Unless otherwise expressly set forth in the relevant
									Product Order, each Product Order incorporates the terms and conditions of this Agreement, and in no event will any different or additional terms of a purchase
									order or similar document issued by Licensee in connection with this Agreement or a Product Order apply, and any such additional or different terms are hereby
									rejected by the Licensor.{' '}
								</p>
								<p>
									<strong>“Third Party Component” </strong> means any run time or other elements owned or licensed to Licensor by a third party (other than open source code or
									elements) which may be embedded in the Licensed Software.{' '}
								</p>
								<p>
									<strong>“Third Party Software” </strong>
									means additional or accompanying software owned or licensed by a third party (such as Adobe Acrobat or Microsoft Internet Explorer, but not any open source code or
									elements) that may be specified in the Documentation or in a file accompanying such Licensed Software.{' '}
								</p>
								<h1>2. GRANT OF LICENSE; LICENSE CONDITIONS. </h1>
								<p className="text-justify">
									License Grant. Subject to Licensee’s completely and accurately filling out the separate online order form, and compliance with all the terms and conditions of this
									Agreement (including but not limited to full payment of applicable fees), Licensor grants to Licensee a limited, revocable, non-transferable, non-sublicensable, and
									non-exclusive license to access and use the Licensed Software as authorized under this Agreement for Licensee’s internal business operations and for commercial use
									only of the Licensed Software for the applicable Initial Subscription Term (defined below) or Subsequent Subscription Term (defined below). The Licensee only may
									have access to and use the Software, and may not allow its parent company, subsidiaries, related parties, affiliates, or any third parties from having access to or
									use of the Software. Throughout the license term, Licensee agrees to: (a) implement internal safeguards to prevent any unauthorized copying, distribution,
									installation, use of, or access to, the Licensed Software or any other breach of this Agreement; (b) take all necessary steps to destroy or erase all Licensed
									Software codes, programs, Documentation, and other proprietary information of Licensor before disposing of any media or hardware, (c) not permit use or access of
									the SaaS to provide service bureau, time-sharing or other computing hosting services, (d) modify or create derivative works based upon the SaaS or Documentation,
									(e) reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code of the software used to provide the SaaS or use the Documentation to
									build a similar or competitive product, software or service. Licensee acknowledges and agrees that this Agreement is a services agreement and Licensor will not be
									delivering a copy of its software as part of the SaaS. Licensee acknowledges that the SaaS is provided on a non-exclusive basis. Nothing shall be deemed to prevent
									or restrict Licensor’s ability to provide the SaaS or other software, technology or services to any other parties.
								</p>
								<p className="py-3">
									<strong>3. USE RESTRICTIONS. </strong> Licensee agrees not to, directly or indirectly:{' '}
								</p>
								<p>
									<strong>“Licensed Software” </strong> means the user documentation that Licensor makes available for the SaaS in electronic form or paper form.{' '}
								</p>
								<p className="py-3">
									A. <ins>inserted text</ins> Copy or distribute Software, in whole or in part (such as any portion, feature, function, or user interface);{' '}
								</p>
								<p className="py-3">
									B. <ins>No selling, leasing or licensing. </ins> Use the Licensed Software as a service, or for timesharing, facilities management, outsourcing, hosting, service
									bureau use, or for providing other application service (ASP) or data processing services to third parties or for like purposes, or permit the use of the Licensed
									Software by a third party or permit access by or use for the benefit of any third party without executing a separate Agreement for the Licensed Software and paying
									Licensor the applicable required additional fees;{' '}
								</p>
								<p className="py-3">
									C. <ins>No Modifying.</ins> Modify or create derivative works of the Software, or decrypt, translate, disassemble, recompile, decompile or reverse engineer the
									Software or otherwise obtain the source code or use the Documentation to build a similar or competitive product, software or service or attempt to do any of the
									aforementioned things (except to the extent applicable law specifically permits such activity, in which case Licensee must provide Licensor with detailed
									information regarding such activities);{' '}
								</p>
								<p className="py-3">
									D. <ins>Proprietary Notices.</ins> Alter, destroy, or otherwise remove any proprietary notices or labels on or embedded within the Licensed Software;
								</p>
								<p className="py-3">
									E. <ins>No Personal or Non-Commercial Use.</ins> Use the Licensed Software in a manner other than as specifically permitted in this Agreement or use the Software
									for personal or non-commercial use or use the Software in any way that violates any applicable laws;{' '}
								</p>
								<p className="py-3">
									F. <ins>No Sublicense.</ins> Assign, sell, resell, license, rent, lease, lend, sublicense, outsource or otherwise transfer the Licensed Software to any third party;
								</p>
								<p className="py-3">
									G. <ins>No Authorization.</ins> Authorize, allow or appoint any third party to do any of the foregoing. For the avoidance of doubt, third parties include, without
									limitation, contractors and consultants (including contractors and consultants retained to provide services solely for the benefit of Licensee), outsourcers,
									Licensee’s affiliates and subsidiaries, parent companies, customers, and the public; or
								</p>
								<p className="py-3">
									H. <ins>Evaluations or Benchmarking.</ins> Publish or disclose to third parties any evaluation or benchmarking of the Licensed Software without Licensor's prior
									written consent.
								</p>
								<p className="py-3">
									<strong>4. LICENSEE RESPONSIBILITIES. </strong>{' '}
								</p>
								<p className="py-3">
									A. <ins>Assistance. </ins> Licensee shall provide commercially reasonable information and assistance to Licensor to enable Licensor to deliver the SaaS Services.
									Licensee acknowledges that Licensor’s ability to deliver the SaaS Services in the manner provided in this Agreement may depend upon the timeliness of such
									assistance.
								</p>
								<p className="py-3">
									B. <ins>Compliance with Laws. </ins> Licensee shall comply with all applicable local, state, national and foreign laws in connection with its use of the SaaS,
									including those laws related to data privacy, international communications, and the transmission of technical or personal data. Licensee acknowledges that Licensor
									exercises no control over the content of the information transmitted by Licensee through the SaaS Services. Licensee shall not upload, post, reproduce or distribute
									any information, software or other material protected by copyright, privacy rights, or any other intellectual property right without first obtaining the written
									permission of the owner of such rights.{' '}
								</p>
								<p className="py-3">
									C. <ins>Unauthorized Use; False Information. </ins> Licensee shall: (a) notify Licensor immediately of any unauthorized use of any password or user id or any other
									known or suspected breach of security, (b) report to Licensor immediately and use reasonable efforts to stop any unauthorized use of the SaaS that is known or
									suspected by Licensee, and (c) not provide false identity information to gain access to or use the SaaS.{' '}
								</p>
								<p className="py-3">
									D. <ins>Administrator Access. </ins> Licensee shall be solely responsible for the acts and omissions of its administrator users. Licensor shall not be liable for
									any loss of data or functionality caused directly or indirectly by the administrator users or any other users.{' '}
								</p>
								<p className="py-3">
									E. <ins>Feedback. </ins> Licensor shall have a royalty-free, worldwide, irrevocable, perpetual license to use and incorporate into the SaaS Services any
									suggestions, enhancement requests, recommendation or other feedback provided by Licensee, including employees, consultants, contractors, and users, relating to the
									SaaS.
								</p>
								<p className="py-3">
									F. <ins>Licensee Liability for its End-Users. </ins> Licensee shall ensure that it, its employees, contractors, consultants, and its end users’ (“End Users”) use of
									the SaaS complies with this Agreement, and Licensee shall be responsible and liable for any breaches to this Agreement by its End Users, and such breaches by the
									End Users are considered a breach by the Licensee to this Agreement.
								</p>
								<p className="py-3">
									<strong>5. LICENSOR’S RESPONSIBILITES. </strong>{' '}
								</p>
								<p className="text-justify">
									Licensor shall use reasonable measures to keep Licensee’s Confidential Information (defined below), confidential and not disclose Licensee’s Confidential
									Information to any third parties, without the Licensee’s written consent. Licensor shall not use Licensee’s Confidential Information for any purpose other than to
									deliver the services under this SaaS agreement. Licensor may disclose Confidential Information to its employees, contractors and agents who need to know it in order
									to assist in the delivery of services under this SaaS agreement, and who have agreed in writing to keep it confidential.
								</p>
								<p className="py-3">
									<strong>6. TERM OF LICENSE. </strong>{' '}
								</p>
								<p className="text-justify">
									The term begins when Licensor provides access to the SaaS to Licensee or when Licensee first begins to use the SaaS, whichever date is earlier (“Effective Date”),
									and ends a year thereafter (“Initial Subscription Term”). The term shall automatically renew for additional one (1) year terms (“Subsequent Subscription Term”),
									unless either Party gives the other Party written notice at least thirty (30) days prior to the end of the Initial Subscription Term or Subsequent Subscription
									Term, whichever is applicable. Licensee shall not access or use the SaaS, during the Initial Subscription Term or any Subsequent Subscription Term until Licensor
									has been paid in full. Prices for any Initial Subscription Term or Subsequent Subscription Term may increase at any time prior to such said term, without notice to
									Licensee. Licensor may terminate this Agreement, along with any or all licenses then in effect with Licensee, immediately by giving Licensee notice of termination
									in the event that (a) Licensee breaches any term or condition of this Agreement and fails to remedy such breach within three (3) days of receipt of Licensor’s
									notice of such breach; (b) Licensee becomes insolvent, has a receiver appointed, or files for or has filed against it, liquidation, bankruptcy or analogous
									proceedings; or (c) Licensee infringes or misappropriates the intellectual property rights of Licensor. Termination shall be without prejudice to any other rights
									or remedies Licensor may have. In the event of any termination, Licensee’s license(s) to access or use the Licensed Software will immediately terminate, and
									Licensee shall destroy and erase all copies of such Licensed Software in its possession or control and provide written certification to Licensor that it has
									complied with this provision. Expiration or termination of this Agreement shall not entitle Licensee to any refund or reimbursement of any previously paid
									fees.Notwithstanding anything to the contrary, Licensor may terminate this agreement for any reason or no reason (convenience), at any time, by providing notice to
									the Licensee.{' '}
								</p>
								<p className="py-3">
									<strong>7. DISCLAIMER OF WARRANTIES. </strong>{' '}
								</p>
								<p className="text-justify">
									{' '}
									THE LICENSED SOFTWARE IS PROVIDED TO LICENSEE “AS-IS” WITHOUT WARRANTY OF ANY KIND. LICENSOR DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE LICENSED SOFTWARE
									WILL MEET LICENSEE’S REQUIREMENTS, THAT OPERATION WILL BE UNINTERRUPTED, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE, OR WORK IN COMBINATION WITH ANY OTHER SOFTWARE,
									APPLICATIONS, OR SYSTEMS, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. EXCEPT AS SET
									FORTH HEREIN AND TO THE EXTENT PERMITTED BY LAW, ALL OTHER WARRANTIES WITH RESPECT TO THE LICENSED SOFTWARE, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
									INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NONINFRINGEMENT, AND WARRANTIES THAT MAY
									ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE ARE EXPRESSLY DISCLAIMED BY LICENSOR AND ITS THIRD-PARTY SUPPLIERS AND AFFILIATES.
									LICENSEE ACKNOWLEDGES THAT LICENSEE IS RESPONSIBLE FOR THE SELECTION OF THE LICENSED SOFTWARE TO ACHIEVE LICENSEE’S INTENDED RESULTS AND FOR THE INSTALLATION AND/OR
									USE OF, AND RESULTS OBTAINED FROM, THE LICENSED SOFTWARE.{' '}
								</p>
								<p className="py-3">
									<strong>8. LIMITATION OF LIABILITY. </strong>{' '}
								</p>
								<p className="py-3">
									A. <ins>Aggregate Cap.</ins> TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT SHALL ANY LIABILITY OF LICENSOR OR ITS AFFILIATES OR ANY OF ITS OR THEIR
									RESPECTIVE LICENSORS OR SERVICE PROVIDERS UNDER OR IN CONNECTION WITH THIS AGREEMENT EXCEED, IN THE AGGREGATE, THE AMOUNTS PAID BY LICENSEE FOR THE PRIOR TWELVE
									(12) MONTHS FOR THE EVENT GIVING RISE TO THE APPLICABLE CLAIM.{' '}
								</p>
								<p className="py-3">
									B. <ins>Waiver of Liability. </ins> TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT SHALL LICENSOR OR ITS AFFILIATES OR ANY OF ITS OR THEIR RESPECTIVE
									LICENSORS OR SERVICE PROVIDERS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR SIMILAR DAMAGES, LOSS OF PROFITS, BUSINESS, DATA, OR
									PROGRAMS (INCLUDING, BUT NOT LIMITED TO, THE COST OF RECOVERING OR REPLACING SUCH DATA OR PROGRAMS), LOSS, DAMAGE OR ANY COSTS DUE TO INTERRUPTION, DELAY, OR
									INABILITY TO USE THE LICENSED SOFTWARE, WHETHER ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, EVEN IF INFORMED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE.{' '}
								</p>
								<p className="py-3">
									C. <ins>Scope. </ins> THE LIMITATIONS AND EXCLUSIONS OF THIS SECTION 8 APPLY TO ALL CAUSES OF ACTION, INCLUDING, WITHOUT LIMITATION, BREACH OF CONTRACT, BREACH OF
									WARRANTY, NEGLIGENCE, STRICT LIABILITY, MISREPRESENTATION AND OTHER TORTS. THESE LIMITATIONS AND EXCLUSIONS APPLY COLLECTIVELY TO LICENSOR, ITS PARENTS, AFFILIATES,
									AND SUBSIDIARIES AND EACH OF THEIR RESPECTIVE EMPLOYEES, CONTRACTORS, AND SUPPLIERS. NOTWITHSTANDING THE FOREGOING, NOTHING IN THIS SECTION 8 EXCLUDES LIABILITY FOR
									GROSS NEGLIGENCE, WILLFUL MISCONDUCT OR FRAUDULENT MISREPRESENTATION.
								</p>
								<p className="py-3">
									D. <ins>Exclusive Remedy. </ins> LICENSEE’S REMEDIES IN THIS AGREEMENT ARE LICENSEE’S EXCLUSIVE REMEDIES. LICENSEE AGREES THAT, IN ENTERING INTO THIS AGREEMENT, IT
									DID NOT RELY ON ANY REPRESENTATIONS (WHETHER WRITTEN OR ORAL) OF ANY KIND OTHER THAN THOSE EXPRESSLY SET OUT IN THIS AGREEMENT.{' '}
								</p>
								<p className="py-3">
									E. <ins>Essential Purpose. </ins> LICENSEE FURTHER ACKNOWLEDGES THAT THE LIMITATIONS AND EXCLUSIONS OF LIABILITY IN THIS SECTION 8 APPLY TO THE FULLEST EXTENT
									PERMITTED BY LAW AND ARE AN ESSENTIAL ELEMENT OF THIS AGREEMENT AND THAT, IN THE ABSENCE OF SUCH LIMITATIONS AND EXCLUSIONS, THE PRICING AND OTHER TERMS AND
									CONDITIONS SET FORTH HEREIN WOULD BE SUBSTANTIALLY DIFFERENT. THE LIMITATIONS AND EXCLUSIONS SET FORTH IN THIS SECTION 8 SHALL APPLY EVEN IF THE LICENSEE’S REMEDIES
									UNDER THIS AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE.{' '}
								</p>
								<p className="py-3">
									<strong>9. INDEMNIFICATION. </strong>{' '}
								</p>
								<p className="text-justify">
									LICENSEE WILL INDEMNIFY, DEFEND, AND HOLD HARMLESS LICENSOR AND ITS PARENT, SUBSIDIARIES, AND AFFILIATES AND ITS AND THEIR RESPECTIVE MEMBERS, MANAGERS,
									SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, CONTRACTORS, SUPPLIERS, AND AGENTS FROM AND AGAINST ANY AND ALL INJURIES, DEATH, LIABILITIES, LOSSES, DAMAGES, CLAIMS,
									CAUSES OF ACTIONS, AWARDS, JUDGMENTS, PENALTIES, FINES, FEES, COSTS AND EXPENSES (INCLUDING, WITHOUT LIMITATION, REASONABLE ATTORNEYS’ FEES AND COURT COSTS)
									ASSERTED AGAINST OR INCURRED BY THE LICENSOR, ARISING OUT OF OR RELATED TO, WHETHER DIRECTLY OR INDIRECTLY,: (A) A BREACH BY LICENSEE OF THE OBLIGATIONS SET FORTH
									IN THIS AGREEMENT; (B) THE BREACH OR VIOLATION OF APPLICABLE LAWS BY LICENSEE; OR (C) THE NEGLIGENCE, GROSS NEGLIGENCE, OR WILLFUL MISCONDUCT BY LICENSEE.{' '}
								</p>
								<p className="py-3">
									<strong>10. HIGH RISK USES. </strong>{' '}
								</p>
								<p className="text-justify">
									The Licensed Software is not fault tolerant, nor designed, manufactured, or intended for use in hazardous environments requiring fail-safe performance (including,
									without limitation, the operation of nuclear facilities, aircraft navigation or communication systems, air traffic control, direct life support machines, or weapons
									systems) in which failure of the Licensed Software could lead directly or indirectly to death, personal injury, or severe physical or environmental damage. Licensor
									and its suppliers shall have no liability for any use of the Licensed Software in any high-risk situations.{' '}
								</p>
								<p className="py-3">
									<strong>11. OWNERSHIP. </strong>{' '}
								</p>
								<p className="text-justify">
									Subject to the limited license granted herein, Licensor shall own all right, title and interest in and to the Software, Documentation, and anything else provided by
									it under this Agreement, and reserve all rights in and to, the Licensed Software, Documentation, and anything else provided by Licensor, including but not limited
									to, all intellectual property rights. The Licensed Software is provided under license, and not sold, to Licensee. The only rights in the Licensed Software is the
									limited license expressly stated in this Agreement and no other rights are implied or granted by estoppel. Licensee shall use reasonable efforts to safeguard the
									Licensed Software from infringement, misappropriation, theft, misuse, or unauthorized access or use. Licensee shall promptly notify Licensor if it becomes aware of
									any infringement, misappropriation or unauthorized access or use of the Licensed Software and shall fully cooperate with Licensor, at Licensor’s expense, in any
									legal action taken by Licensor to enforce its intellectual property rights.{' '}
								</p>
								<p className="py-3">
									<strong>12. THIRD PARTY SOFTWARE AND COMPONENTS. </strong>{' '}
								</p>
								<p className="text-justify">
									The Licensed Software may come with or require Third Party Software that Licensee shall license directly from the third party licensor pursuant to such third
									party’s terms and conditions and not this Agreement. Additionally, some Licensed Software may include certain Third Party Components and open source software. Such
									open source software and Third Party Components may also be loaded on the Licensed Software media. Third Party Components are licensed to Licensee under this
									Agreement; open source software is licensed pursuant to the applicable open source license. To the extent applicable, information about the open source software may
									be found (a) in a file accompanying the applicable Licensed Software or (b) in the Documentation. Licensee shall not directly access any Third Party Components
									other than with or as part of the Licensed Software. Licensee agrees that to the extent required by a third party licensor or supplier of a Third Party Component,
									that third party licensor or supplier is an intended third party beneficiary of this Agreement as necessary to protect intellectual property rights in the Licensed
									Software and limit certain uses thereof.{' '}
								</p>
								<p className="py-3">
									<strong>13. CONFIDENTIAL INFORMATION. </strong>{' '}
								</p>
								<p className="text-justify">
									“Confidential Information” means any oral, written or electronic information and data related to any of the following: software, software object and source codes,
									SaaS, algorithms, developments, documentation, business, financial, technical, research, marketing and advertising, product, services, trade secrets, know-how,
									developments, inventions, patents, patent applications, intellectual property, processes, techniques, engineering designs and drawings, employees, contractors,
									agents, customers, suppliers, manufacturers, resellers, wholesalers, retailers, pricing, watches, inventory, costs, profits, and Confidential Information shall also
									include any information and data, that under the circumstances, appear to a reasonable person to be confidential or proprietary. Confidential Information shall not
									include any information that the Party receiving Confidential Information (“Recipient”) from the disclosing Party (“Disclosing Party”) can prove (a) is in the
									public domain through no fault of the Recipient; (b) was known to the Recipient, without restriction, at the time of disclosure, as demonstrated by evidence in
									existence at the time of disclosure; (c) becomes known to the Recipient, without restriction, from a source other than Disclosing Party without breach of this
									Agreement by Recipient and otherwise not in violation of Disclosing Party’s rights; or (d) was independently developed by Recipient without any use or referring to
									the Disclosing Party’s Confidential Information, as demonstrated by evidence created at the time of such independent development. The Recipient shall not disclose
									any Confidential Information to any third-parties, and Licensee shall use reasonable care to keep secure Confidential Information and ensure it is not accessed by
									or disclosed to any third-parties. Recipient may disclose Confidential Information to employees, contractors and agents who need to know it, and who have agreed in
									writing to keep it confidential. Notwithstanding anything to the contrary, the Recipient may disclose Confidential Information to the extent required by applicable
									law, court order or subpoena, provided that the Recipient gives prompt notice to Disclosing Party of such disclosure requirement prior to disclosing any
									Confidential Information and comply with Disclosing Party’s reasonable requests regarding its effort to oppose or limit such disclosure. Upon the expiration or
									termination of this Agreement or License, the Licensee shall destroy or delete all Confidential Information promptly, and an authorized officer of the Licensee
									shall certify in a signed writing of its compliance with this requirement and promptly deliver such executed certified writing to the Licensor.{' '}
								</p>
								<p className="py-3">
									<strong>14. LICENSE FEES AND PAYMENT TERMS. </strong>{' '}
								</p>
								<p className="text-justify">
									For payment purposes, each Initial Subscription Term or Subsequent Subscription Term, whichever is applicable, shall be divided into monthly payments. Payments by
									Licensee shall be done monthly by credit card acceptable to Licensor. Licensor may keep a record of the Licensee’s credit card for monthly payment purposes. It is
									up to the Licensee to keep its credit card information current and communicate any changes to the Licensor. Licensee authorizes Licensor to automatically charge
									Licensee’s credit card for the Initial Subscription Term or Subsequent Subscription Term, whichever is applicable, monthly charges (including but not limited to all
									Seat fees and Taxes). Licensee agrees to pay the applicable license fees for the Licensed Software for the total number of Seats in the separate Online Order Form
									prior to accessing or using the Software. Software license Seat fees prices do not include any Taxes (defined below). Software license Seat fees are non-refundable.
									Licensor may charge Licensee for any Taxes related to the Software. Software License Seat fees are exclusive of any applicable transportation charges, sales, use,
									value added tax, and other applicable taxes and duties (collectively “Taxes”), and all such amounts shall be paid or reimbursed by Licensee. Licensee is solely
									responsible and liable for all taxes, duties and related costs and fees. If Licensor pays any Taxes which were owed by Licensee, then Licensee shall immediately
									reimburse Licensor for such Taxes. Licensee shall be liable for all outstanding past due amounts, which shall accrue interest at the rate of 1.5% per month
									compounded or, if lower, the maximum rate allowed by applicable law, and any collection costs associated with the collection of any past due amounts.{' '}
								</p>
								<p className="py-3">
									<strong>15. RELATED SERVICES. </strong>{' '}
								</p>
								<p className="text-justify">
									Licensee shall be solely responsible for obtaining and installing all proper equipment, computer hardware and other third party support software (including
									operating systems) for the proper access and use of the Licensed Software.{' '}
								</p>
								<p className="py-3">
									<strong>16. AUDITS. </strong> Licensor, or an auditor selected by Licensor, has the right to verify Licensee’s compliance with the license issued under this
									Agreement. Licensee agrees to:{' '}
								</p>
								<p className="py-3">
									A. <ins>Recordkeeping. </ins> Keep, and upon Licensor’s request, provide records, sufficient to certify Licensee’s compliance with this Agreement;{' '}
								</p>
								<p className="py-3">
									B. <ins>Questionnaire. </ins>Within ten (10) days of Licensor’s request, Licensee shall furnish to Licensor or its designated auditor (“Auditor”) a completed
									questionnaire provided by Licensor or Auditor, accompanied with a written statement signed by a director of Licensee certifying the accuracy of the information
									provided; and{' '}
								</p>
								<p className="py-3">
									C. <ins>Access. </ins>Provide representatives of Licensor or Auditor any necessary assistance and access to records and computers to allow an inspection and audit
									of Licensee’s computers and records, during Licensee’s normal business hours, for compliance with licenses, and this Agreement, and fully cooperate with such audit.{' '}
								</p>
								<p className="py-3">
									D. <ins>Non-Compliance. </ins>In the event that Licensee has, or at any time has had, unlicensed use of, or access to the Licensed Software or has otherwise
									breached this Agreement (a “Non-Compliance”), without prejudice to any other rights or remedies Licensor may have, including, without limitation, injunctive relief,
									Licensee shall, immediately upon receipt of notice of such Non-Compliance to Licensee, purchase sufficient licenses to cure the Non-Compliance, by paying Licensor’s
									current (as of the date of such additional purchase) license fees, and interest (compounded at 1.5% monthly or the maximum rate permitted by applicable law if
									lower) for such additional licenses for the time period from the commencement of the Non-Compliance until payment of the aforementioned fees, with interest payable
									even if an invoice was not issued at the time the Non-Compliance occurred. If Licensee’s Non-Compliance results in an underpayment of license fees of 5% or greater,
									Licensee shall also reimburse Licensor for the reasonable cost of such audit in addition to other amounts due. In the event of a dispute related to a
									Non-Compliance, Licensor shall have the right to collect from Licensee its reasonable costs and attorneys' fees incurred in enforcing this Agreement.{' '}
								</p>
								<p className="py-3">
									<strong>17. PRIVACY AND USE OF LICENSEE INFORMATION. </strong>{' '}
								</p>
								<p className="py-3">
									A. <ins>Responsibility and Compliance with Laws. </ins>
									Licensee is solely responsible for and assumes all liability with respect to its own collection, processing, storage, and transfer of any user data, including, but
									not limited to, personally identifiable information and personal health and financial information (collectively, “Personal Information”). Licensee shall be solely
									responsible for notifying its users of proper use of such data. Each party is responsible for complying with its respective obligations under all applicable laws,
									regulations, and industry standards regarding data collection and data privacy applicable for the use of the SaaS by the relevant party. Licensee shall not provide
									any Personal Information to Licensor for processing by Licensor on behalf of Licensee, unless otherwise agreed by the parties in writing in an applicable executed
									transaction document with applicable privacy terms.
								</p>
								<p className="py-3">
									B. <ins>Consent to Use of Licensee Information. </ins>
									To the extent required or permitted by law, Licensee hereby expressly consents to (a) receiving information from Licensor from time to time advertising Licensor’s
									products; (b) the use of Licensee’s name in Licensor customer lists, promotional materials, and press releases; and (c) the collection and use of information about
									the computer system on which the Licensed Software is installed (e.g. product version, serial number) for internal security and licensing purposes.{' '}
								</p>
								<p className="py-3">
									C. <ins>Other Use of Licensee Information. </ins>
									To the extent required or permitted by law, and notwithstanding the terms in Section 17A, Licensor may also process personally identifiable information of Licensee
									and Licensee’s users (a) in order to comply with a legal obligation to which Licensor is subject; (b) as is necessary for the performance of this Agreement; and (c)
									where necessary for the purposes of Licensor’s legitimate interests, except where such interests are overridden by the interests or fundamental rights and freedoms
									of the Licensee or Licensee’s users which require protection of personally identifiable information.{' '}
								</p>
								<p className="py-3">
									<strong>18. MISCELLANEOUS. </strong>{' '}
								</p>
								<p className="py-3">
									A. <ins>Assignment. </ins>Licensor may assign this Agreement, including any rights or obligations under the Agreement (in whole or in part) to a parent, subsidiary,
									affiliate, or third-party, without the consent of the Licensee. Licensor may use consultants, contractors or subcontractors to fulfill its obligations under this
									Agreement without the consent of the Licensee. Licensee may not assign or transfer this Agreement or any of its rights or duties hereunder, including (but not
									limited to) by merger, acquisition by any entity of all or substantially all of Licensee’s stock or assets, change of control, operation of law, or otherwise,
									without the prior written consent of Licensor and payment by Licensee of the applicable assignment fee. Any attempted assignment not in accordance with this Section
									shall be null and void.{' '}
								</p>
								<p className="py-3">
									B. <ins>Governing Law. </ins>The laws of the State of California, excluding any conflict of law principles, govern this Agreement and the licenses granted
									hereunder, and the parties hereto irrevocably consent to the exclusive jurisdiction of the state and federal courts of Los Angeles County, California, U.S.A. in any
									action based on this Agreement or the Licensed Software hereunder. Notwithstanding anything to the contrary, the Licensor may seek equitable relief or an injunction
									against the Licensee with any state, federal or international court or tribunal. Each party waives any right it may have to object to such venue, including
									objections based on personal jurisdiction or forum non conveniens (inconvenient forum). The parties agree that the Uniform Computer Information Transaction Act or
									any version thereof, adopted by any state, in any form ("UCITA"), shall not apply to this Agreement. To the extent that UCITA is applicable, the parties hereby opt
									out of the applicability of UCITA pursuant to the opt out provision(s) contained therein. The United Nations Convention on Contracts for the International Sale of
									Goods is expressly and entirely excluded{' '}
								</p>
								<p className="py-3">
									C. <ins>Export Control. </ins>This Agreement may be subject to export control laws, regulations, and other restrictions of the United States (including, but not
									limited to, the U.S. Export Administration Regulations (the “EAR”)), United Kingdom, or the European Union regarding export or re-export of computer software and
									technology. Licensee agrees to comply with all applicable export control laws, regulations, and restrictions, including the EAR, where applicable.{' '}
								</p>
								<p className="py-3">
									D. <ins>Entire Agreement. </ins>This Agreement, constitutes the complete and exclusive statement of agreement between the parties relating to the license for the
									Software and supersedes all prior proposals, communications, purchase orders, and agreements (including, without limitation, other agreements), without need for a
									mutually executed amendment to any such other agreement.{' '}
								</p>
								<p className="py-3">
									E. <ins>Amendment. </ins>. Licensor may amend or modify this Agreement at any time without notice to the Licensee and such amendments or modifications are effective
									when posted. It is up to the Licensee to check from time to time to see if the Agreement has changed.{' '}
								</p>
								<p className="py-3">
									F. <ins>Waiver. </ins>No waiver of any right under this Agreement will be effective unless in writing and signed by authorized representatives of both parties
									(excluding any distributor or reseller of Licensor). The failure of either party to this Agreement to object to, or to take affirmative action with respect to, any
									conduct of the other party that is in violation of the terms of this Agreement shall not be construed as a waiver thereof, or as a waiver of any future breach or
									subsequent wrongful conduct.{' '}
								</p>
								<p className="py-3">
									G. <ins> Severability. </ins>If any provision in this Agreement is invalid or unenforceable, that provision will be construed, limited, modified or, if necessary,
									severed, to the extent necessary, to eliminate its invalidity or unenforceability, and the other provisions of this Agreement will remain unaffected.{' '}
								</p>
								<p className="py-3">
									H. <ins>No Reliance. </ins>Each party acknowledges that in entering into this Agreement it has not relied on any representations, agreements, warranties or other
									assurances (other than those repeated in this Agreement) and waives all rights and remedies which but for this Section 17 would be available to it.{' '}
								</p>
								<p className="py-3">
									I. <ins>Headings. </ins>The headings contained in this Agreement are for reference purposes only and shall not affect in any way the meaning or interpretation of
									this Agreement.{' '}
								</p>
								<p className="py-3">
									J. <ins>Survival. </ins>The rights and obligations of the parties contained in the following sections, including all subsections thereof, Section 1 (Licensor and
									Licensee), Section 2 (Grant of License; License Conditions), Section 3 (Use Restrictions), 4 (Licensee Responsibilities), 6(Term of License), 7 (Disclaimer of
									Warranties), 8 (Limitation of Liability), 9 (Indemnification), 10 (High Risk Uses), 11 (Ownership), 12 (Third Party Software and Components), 13 (Confidential
									Information), 14 (License Fees and Payment Terms), 16 (Audits), 17 (Privacy and Use of Licensee Information), 18 (Miscellaneous), Exhibit A (Product Order), and
									terms and conditions of this Agreement which by their nature extend beyond expiration or termination of this Agreement shall survive the expiration or termination
									of this Agreement to the full extent necessary for the enforcement and for the protection of the party in whose favor they operate.{' '}
								</p>
								<p className="py-3">
									K. <ins>Notices. </ins>Under this Agreement, notices to Licensee shall be sent to the email address Licensor has on file, and notices to Licensor shall be sent to
									info@elefta.com. Notice will be treated as received when the email is sent, as long as, there is no message that the email was not sent or error message. Licensee
									is responsible for keeping its email address current throughout the Term of this Agreement. Email address may be changed by sending the other party an email
									notifying them of such change or the Licensor may otherwise change its email address through updated terms and conditions or Agreement. The parties may use emails
									to satisfy written approval and consent requirements under this Agreement.{' '}
								</p>
							</div>
						</div>
					</div>
					<div className="flex justify-between w-full relative px-8 pb-5 pt-2 flex items-center" style={{backgroundColor: '#2c79e0'}}>
						{showButtons && (
							<>

								<Tooltip title={disabledButton ? "Scroll to the end to enable the button" : "" }placement="bottom" arrow>
									<button
										type="button"
										className={Classnames("btn btn-outline-dark text-black px-5 py-3 rounded",
											{"bg-white": !disabledButton,
												"bg-[#ccc]": disabledButton
											})}
										disabled={disabledButton}
										onClick={(event) => {
											updateFormData({
												...formData,
												['terms']: true,
											})
											hideTerms()
											if(isFunction(handleSubmit) && newUser !== 'true') handleSubmit(event, {acceptToc: true})
										}}
									>
										{'Accept'}
									</button>
								</Tooltip>
								<Tooltip title={disabledButton ? "Scroll to the end to enable the button" : "" }placement="bottom" arrow>
									<button
										type="button"
										className={Classnames("btn btn-outline-dark  text-black px-5 py-3 float-right", {
											"opacity-50": disabledButton,
										})}
										disabled={disabledButton}
										onClick={() => {
											updateFormData({
												...formData,
												['terms']: false,
											})
											hideTerms()
										}}
									>
										Reject
									</button>
								</Tooltip>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default TermsOfUse
